var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.$route.name !== "products",
          expression: "$route.name !== 'products'",
        },
      ],
      staticClass: "footer",
      class: {
        "footer--suppliers":
          _vm.$route.name === "footwearSuppliers" ||
          _vm.$route.name === "componentSuppliers",
      },
    },
    [
      _c("div", { staticClass: "footer__container" }, [
        _c(
          "div",
          { staticClass: "footer__credits" },
          [
            _c("router-link", {
              staticClass: "footer__logo",
              attrs: { to: { name: "home" } },
              domProps: { innerHTML: _vm._s(_vm.svg.logo) },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "footer__copy" }, [
              _vm._v("© FindSourcing 2018"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("ul", { staticClass: "footer-menu" }, [
          _c("li", { staticClass: "footer-menu__item" }, [
            _c("div", { staticClass: "footer-menu__sub-item" }, [
              _c("span", { staticClass: "footer-menu__link" }, [
                _vm._v("About"),
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "footer-submenu" }, [
                _c(
                  "li",
                  { staticClass: "footer-submenu__item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "footer-submenu__link",
                        attrs: { to: { name: "about" } },
                      },
                      [_vm._v("About FindSourcing")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "footer-submenu__item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "footer-submenu__link",
                        attrs: { to: { name: "privacy-policy" } },
                      },
                      [_vm._v("Privacy Policy")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "footer-menu__item footer-menu__item--last" },
            [
              _c("div", { staticClass: "footer-menu__sub-item" }, [
                _c(
                  "a",
                  { staticClass: "footer-menu__link", attrs: { href: "#" } },
                  [_vm._v("Get in touch")]
                ),
                _vm._v(" "),
                _c("ul", { staticClass: "footer-submenu" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("ul", { staticClass: "footer-social" }, [
                    _c("li", { staticClass: "footer-social__item" }, [
                      _c("a", {
                        staticClass: "footer-social__link",
                        attrs: {
                          href: "https://www.facebook.com/FindSourcing/",
                          target: "_blank",
                          rel: "noopener noreferrer",
                        },
                        domProps: { innerHTML: _vm._s(_vm.svg.fb) },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "footer-social__item" }, [
                      _c("a", {
                        staticClass: "footer-social__link",
                        attrs: {
                          href: "https://www.linkedin.com/company/11406930/",
                          target: "_blank",
                          rel: "noopener noreferrer",
                        },
                        domProps: { innerHTML: _vm._s(_vm.svg.linkedIn) },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "footer-submenu__item" }, [
      _c(
        "a",
        {
          staticClass: "footer-submenu__link",
          attrs: { href: "https://careers.resourced.com" },
        },
        [_vm._v("Careers")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "footer-submenu__item" }, [
      _c(
        "a",
        {
          staticClass: "footer-submenu__link",
          attrs: { href: "mailto:contact@findsourcing.com" },
        },
        [_vm._v("Contact Us")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }