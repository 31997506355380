export default function (store, { $http }) {
  return {
    loaded: null,
    get isLoaded() {
      return this.loaded !== null;
    },
    allStatuses() {
      if (!this.loaded) {
        throw new Error('ComponentStatuses have not been loaded yet.');
      }

      return this.loaded;
    },
    getById(id) {
      if (!this.isLoaded) {
        throw new Error('ComponentStatuses have not been loaded yet.');
      }

      return this.loaded.find((status) => status.id === id);
    },
    async ensureAllLoaded() {
      if (!this.isLoaded) {
        return await this.loadAll();
      }

      return this.loaded;
    },
    /*
        Example of how to create component statuses for a brand.
        
        $store.componentStatuses.create("Approved", "rgb(90, 213, 121)")
        $store.componentStatuses.create("In Development", "rgb(247, 186, 100)")
        $store.componentStatuses.create("Not Approved", "rgb(229, 73, 73)")
     */
    async create(name, color) {
      const brandId = store.user.brandId;

      const { data: componentStatus } = await $http.post(`/api/brand/${brandId}/component-statuses`, { name, color });

      this.loaded?.push(componentStatus);

      return componentStatus;
    },
    async update(id, name, color) {
      const brandId = store.user.brandId;

      const { data: componentStatus } = await $http.put(`/api/brand/${brandId}/component-statuses/${id}`, {
        name,
        color,
      });

      if (this.loaded) {
        this.loaded.findIndex((c) => c.id === id);
        this.loaded[index] = componentStatus;
      }

      return componentStatus;
    },
    async loadAll() {
      const brandId = store.user.brandId;

      const { data: componentStatuses } = await $http.get(`/api/brand/${brandId}/component-statuses`);
      this.loaded = componentStatuses;

      return { componentStatuses };
    },
  };
}
