// Object => query string without taking any special cases into consideration to keep it lean
export default function qs(queryObject) {
  return Object.entries(queryObject).reduce((queryString, [key, value]) => {
    if (value === null) return queryString;

    queryString += queryString === '' ? '?' : '&';

    queryString += `${key}=${value}`;

    return queryString;
  }, '');
}
