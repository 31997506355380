export default function (store, { $http }) {
  return {
    all: {},
    load(name) {
      return $http.get(`/api/pages/${name}`).then((response) => {
        this.all[name] = response.data;

        return Promise.resolve(response.data);
      });
    },
  };
}
