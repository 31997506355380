export default function (store, { $http }) {
  return {
    async uploadFile(inFile, progress) {
      const { data: file } = await $http.post('/api/company/upload-file', inFile, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: !progress
          ? null
          : function (progressEvent) {
              let percentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));

              progress(percentage);
            },
      });

      return { file };
    },
  };
}
