import Vue from 'vue';
import { createRouter } from './router';
import { createStore } from './store';
import Frame from 'pages/frame/index.vue';
import VueScrollTo from 'vue-scrollto';
import VueAnalytics from 'vue-analytics';
import Toasted from 'vue-toasted';
import Popover from 'common/plugins/vue-ontop';
import EasyStore from 'common/plugins/vue-easystore';
import * as Sentry from '@sentry/umd'; // Fake package that get's resolved server/client (@sentry/browser vs @sentry/node) using webpack alias
import * as Integrations from '@sentry/integrations';

// Devtools
Vue.config.devtools = process.env.NODE_ENV == 'development';

Vue.use(VueScrollTo);

Vue.use(Popover);

Vue.prototype.$blobUrl = APP_CONFIG.blobUrl;

// Vue ClipBoard
import Clipboard from 'v-clipboard';
Vue.use(Clipboard);

// Notifications
// https://github.com/euvl/vue-notification
import Notifications from 'vue-notification/dist/ssr';
Vue.use(Notifications);

// PLM notifications/snackbar
if (!Vue.prototype.$isServer) {
  Vue.use(Toasted);
}

// Add convenience date format filter
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
Vue.filter('dateformat', function (date, dateFormat, options) {
  try {
    if (typeof date === 'string') date = parseISO(date);

    return format(date, dateFormat, options);
  } catch (e) {
    return '';
  }
});

Vue.use(EasyStore);

Vue.use({
  install: function (Vue) {
    // Mixin for loading async data on routing between the same component
    // with different parameters (see https://ssr.vuejs.org/en/data.html
    // for more details)
    Vue.mixin({
      beforeRouteUpdate(to, from, next) {
        const { load } = this.$options;
        if (load) {
          load({
            store: this.$store,
            route: to,
          })
            .then(next)
            .catch(next);
        } else {
          next();
        }
      },
    });
  },
});

// Error tracking using Sentry
// Use only in prodution due to errors being suppressed in console (which is nice to have when doing development)
// See https://sentry.io/find-sourcing-ab/public-app/getting-started/javascript-vue/ and
// https://github.com/vuejs/vue/issues/8433 for details.
if (process.env.NODE_ENV == 'production') {
  Sentry.init({
    dsn: APP_CONFIG.sentry.dsn,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    release: APP_CONFIG.buildnumber,
  });
}

// export a factory function for creating fresh app, router and store
// instances
export function createApp({ log, state, bootError, $http }) {
  Vue.prototype.$http = $http;

  // Minilog
  Vue.use((Vue) => {
    Vue.$log = log;
    Vue.prototype.$log = Vue.$log;
  });

  // TODO: Only run necessary Vue.use/installs before store is created to
  // avoid having all these trigger when creating the reactive store
  const store = createStore(log, state, $http);
  const router = createRouter(log, store);

  // Google Analytics
  Vue.use(VueAnalytics, {
    id: APP_CONFIG.ga.id,
    router,
    fields: store.user.loggedIn
      ? {
          userId: store.user.current.userName,
        }
      : {},
  });

  const app = new Vue({
    router,
    store,
    render: (createElement) => createElement(Frame, { props: { bootError } }), // Needed due to: https://github.com/vuejs/vue/issues/6134#issuecomment-316895413
  });

  return {
    app,
    Frame,
    store,
    router,
  };
}
