export default function (store, { $http }) {
  return {
    all: {},
    list: [],
    featured: [],
    titles: [],
    load(category, slug) {
      return $http.get(`/api/knowledgebank/${category}/${slug}`).then((response) => {
        this.all[slug] = response.data;

        return Promise.resolve(response.data);
      });
    },
    loadList() {
      return $http.get('/api/knowledgebank/list').then((response) => {
        this.list = response.data;

        return Promise.resolve(response.data);
      });
    },
    loadFeatured() {
      return $http.get('/api/knowledgebank/featured').then((response) => {
        this.featured = response.data;

        return Promise.resolve(response.data);
      });
    },
    loadArticleTitles() {
      return $http.get('/api/knowledgebank/titles').then((response) => {
        this.titles = response.data;

        return Promise.resolve(response.data);
      });
    },
  };
}
