import EasyStore from 'common/plugins/vue-easystore';

// Frontend/general modules
import head from 'apps/common/store/head';
import general from './general';
import countries from './countries';

// Backend modules
import articles from './articles';
import buyers from './buyers';
import categories from './categories';
import companies from 'apps/common/store/companies';
import componentStatuses from './component-statuses';
import componentTypes from './component-types';
import currency from 'apps/common/store/currency';
import knowledgebank from './knowledge-bank';
import pages from './pages';
import quotations from './quotations';
import subscriptions from './subscriptions';
import suppliers from './suppliers';
import user from './user';

export function createStore(log, state, $http) {
  const store = new EasyStore.Store({}, state, { $http });

  store.add({
    // Frontend/general
    head,
    general,
    countries,

    // Backend
    articles,
    buyers,
    categories,
    companies,
    componentStatuses,
    componentTypes,
    currency,
    knowledgebank,
    pages,
    quotations,
    subscriptions,
    suppliers,
    user,
  });

  return store;
}
