import account from 'apps/common/store/account';
import assign from 'obj-assign';
import * as Sentry from '@sentry/umd'; // Fake package that get's resolved server/client (@sentry/browser vs @sentry/node) using webpack alias

export default function (store, { $http }) {
  return assign({}, account(store, { $http }), {
    chineese: false,
    chineeseChecked: false,
    plm: false, // TODO: DELME - temp variable for PLM development
    get company() {
      return this.current && this.current.companies[0];
    },
    get brandId() {
      return this.company?.brandID;
    },
    get isPlm() {
      return this.company?.plm || false;
    },
    get isSupplier() {
      if (!this.company) return false;

      return this.company.type !== 1;
    },
    get isFootwearSupplier() {
      return this.isSupplier && this.company.footwearCapabilities.length;
    },
    get isBuyer() {
      return this.company && !this.isSupplier;
    },
    get needMoreInfo() {
      return this.loggedIn && !this.current.companies.length;
    },
    isSelectedCompany(company) {
      return company.id == this.company?.id;
    },
    hasCompany(companyId) {
      return this.current && this.current.companies && this.current.companies.some((c) => c.id == companyId);
    },
    async externalLogin(companyGuid) {
      let response = await $http.get(
        `/api/account/externalLoginCallback${companyGuid ? '?companyGuid=' + companyGuid : ''}`
      );

      if (response.data.user) this.current = response.data.user;

      return { status: response.data.status, message: response.data.message };
    },
    async signUp(data, companyGuid) {
      let response = await $http.post(`/api/account/register${companyGuid ? '?companyGuid=' + companyGuid : ''}`, data);
      let status = response.data.status;

      // Logged in (either as new or existing user)
      if (status === 1 || status === 3) this.current = response.data.user;

      if (status === 1) this.$ga.event('user', 'Sign up', 'signUp');

      return {
        status,
        emailErrors: response.data.emailErrors,
        passwordErrors: response.data.passwordErrors,
        otherErrors: response.data.otherErrors,
      };
    },
    async forgotPassword(data) {
      let response = await $http.post(`/api/account/forgotPassword`, data);

      return {
        status: response.data.status,
        user: response.data.data,
      };
    },
    async resetPassword(data) {
      let response = await $http.post(`/api/account/resetPassword`, data);
      this.current = response.data.user;

      return {
        status: response.data.status,
      };
    },
    async save(data) {
      let response = await $http.post(`/api/account/save`, data);
      let user = response.data.user;

      this.current = user;

      return user;
    },
    // TODO: REFACTOR - should this be merged with companies.uploadFile??? Maybe not - maybe some parts of it should though (progress, base file/form upload handling???)
    async uploadProfileImage(file, progress) {
      const fd = new FormData();
      fd.append('image', file);

      let { data } = await $http.post(`/api/account/uploadprofileimage`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: !progress
          ? null
          : function (progressEvent) {
              let percentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));

              progress(percentage);
            },
      });

      return data;
    },
    async registerBuyerWithoutPassword(email) {
      let { data } = await $http.post(`/api/account/registerBuyerWithoutPassword`, {
        email: email,
      });

      let success = data.success;
      let error = data.error;
      let user = data.user;

      if (!success) return { success, error };

      this.current = user;

      this.$ga.event('user', 'Sign up', 'registerBuyerWithoutPassword');
      this.$ga.event('user', 'Add buyer company', 'registerBuyerWithoutPassword');

      return { success };
    },
    async registerUserWithoutPassword(email) {
      let response = await $http.post(`/api/account/registerInvitedUser`, { email: email });

      let data = response.data;
      let status = data.status;
      let user = data.user;

      if (status == 0) return { success: false, status };

      if (status == 1) {
        this.current = user;

        this.$ga.event('user', 'Sign up', 'registerUserWithoutPassword');

        return { success: true, status, user: user };
      }

      return { success: false, status };
    },
    async registerSupplierWithoutPassword({ email, supplier, satra }) {
      let {
        data: { success, error, user, id },
      } = await $http.post(`/api/account/registerSupplierWithoutPassword`, {
        email,
        supplier,
        satra,
      });

      if (!success) return { success, error };

      this.current = user;

      this.$ga.event('user', 'Sign up', 'registerSupplierWithoutPassword');
      this.$ga.event('user', 'Add supplier company', 'registerSupplierWithoutPassword');

      return { success, id };
    },
    async connectToCompany(companyGuid) {
      let response = await $http.get(`/api/account/connectToCompany?companyGuid=${companyGuid}`);
      let user = response.data.user;

      this.current = user;

      return { success: true };
    },
    async confirmInvitationAsSupplier(userId, code, confirmConnection) {
      const {
        data: { status, email, id, user },
      } = await $http.get('/api/account/confirmInvitation', { params: { userId, code, confirmConnection } });
      const confirmed = status == 2;

      this.current = user;

      return { confirmed, status, email, id };
    },
    async confirmEmail(userId, code) {
      const {
        data: { status, email },
      } = await $http.get('/api/account/confirmEmail', { params: { userId, code } });
      const confirmed = status == 2;

      return { confirmed, email };
    },
    async confirmSupplierUserConntection(guid) {
      const {
        data: { status },
      } = await $http.get('/api/account/confirmSupplierUserConnection', { params: { guid } });
      const confirmed = status == 1;

      return { confirmed };
    },
    async confirmSupplierEmail(guid) {
      const {
        data: { status },
      } = await $http.get('/api/suppliers/confirmSuppliersEmail', { params: { guid } });
      const confirmed = status == 1;

      return { confirmed };
    },
    async confirmSupplierEmailAndCreateUser(guid) {
      const {
        data: { success, error, email, id, user },
      } = await $http.get('/api/account/confirmInvitationNewUser', { params: { guid } });

      if (success) this.current = user;

      return { success, error, email, id };
    },
    async checkIfChineese() {
      if (this.chineeseChecked) return this.chineese;

      const {
        data: { code, error },
      } = await $http.get(`/api/account/countryFromIP`);

      this.chineeseChecked = true;

      if (error || !code) return this.chineese;
      if (code.toLowerCase() === 'cn') this.chineese = true;

      return this.chineese;
    },
    userUpdated(user) {
      this.updateSentryContext(user);
      this.updateGoogleAnalytics(user);
    },
    updateGoogleAnalytics(user) {
      if (user) {
        this.$ga.set({ userId: user.userName, dimension1: user.userName });
      }
    },
    updateSentryContext(user) {
      // Use only in prodution due to errors being suppressed in console (which is nice to have when doing development)
      // See https://sentry.io/find-sourcing-ab/public-app/getting-started/javascript-vue/ and
      // https://github.com/vuejs/vue/issues/8433 for details.
      if (process.env.NODE_ENV == 'production') {
        const username = user && user.userName;

        Sentry.configureScope((scope) => {
          scope.setUser({ email: username });
        });
      }
    },
    created() {
      this.updateSentryContext(this.current);
      this.$watch('current', this.userUpdated);
    },
  });
}
