import qs from 'apps/public/lib/qs';
import quotations from 'apps/common/store/quotations';

export default function (store, { $http }) {
  return Object.assign({}, quotations(store, { $http }), {
    shallowRequests: [],
    requests: [],
    inProgress: {},
    get myShallowRequests() {
      return this.requests.filter((request) => request.user.id === store.user.current.id);
    },
    async loadRequests({ shallow } = { shallow: false }) {
      let {
        data: { status, requests },
      } = await $http.get(`/api/quotation/requests/company/${store.user.company.id}${qs({ shallow })}`);

      if (status === 0) {
        // Not connected to company - should never happen
        // TODO: FIXME
      }

      this[shallow ? 'shallowRequests' : 'requests'] = requests;
    },
    async addRequest(request) {
      await $http.post('/api/quotation/request', request);

      this.$ga.event('request', 'New request', 'addRequest');
    },
    async updateRequest(request) {
      await $http.put(`/api/quotation/request`, request);

      // TODO: Merge into one backend call?
      await this.loadRequests();
    },
    async addQuotation(requestID, supplierID, quotation) {
      await $http.post(`/api/quotation/add/${requestID}/${supplierID}`, quotation);

      this.$ga.event('quotation', 'New quotation', 'addQuotation');
    },
    async updateQuotationResponse(requestId, quotationId, response) {
      if (this.inProgress.updateQuotationResponse) return;

      try {
        this.inProgress.updateQuotationResponse = true;
        await $http.put(`/api/quotation/updateResponse/${quotationId}`, { response });

        // For now, assume everything was a success and update the quotation client side
        this.fullRequests[requestId].quotations.find((q) => q.id === quotationId).response = response;
      } finally {
        this.inProgress.updateQuotationResponse = false;
      }
    },
    created() {
      // To force Vue'ification of module, since then we can use this.$ga
    },
  });
}
