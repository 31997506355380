var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$route.name, attrs: { id: "app" } },
    [
      _c("navbar"),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "main" },
        [_c("router-view", { key: _vm.$route.fullPath })],
        1
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "loader",
        class: {
          "is-visible":
            _vm.$store.general.loading && !_vm.$store.general.loadingLocked,
        },
      }),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade-in-only" } }, [
        _vm.$store.general.plmLoading
          ? _c("span", { staticClass: "plm-loader" })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("popover-container", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_c("simple-close")]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "popover",
        { attrs: { name: "error" } },
        [
          _c("error-component", {
            attrs: {
              title: _vm.error.title,
              message: _vm.error.message,
              refresh: _vm.error.refresh,
              fixed: _vm.error.fixed,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.$route.path.startsWith("/plm/") ? _c("footer-component") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }