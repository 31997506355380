export default function (store, { $http }) {
  return {
    // TODO/REFACTOR: Rework admin/store/companies.js, common/store/quotations.js,
    // admin/store/quotations.js into a Map or Set, so we handle enums (or
    // enum-like - as in the stages) in a similar fashion. This makes it so
    // that we have fewer ways of handling similar scenarios in, which is
    // better from a maintaibility aspect. (fewer strategies to keep in our
    // heads).
    stages: {
      1: 'Quotation submitted',
      2: 'Global sourcing',
      3: 'Suppliers prepare offers',
      4: 'FindSourcing controlling offers',
      5: 'Deal selection',
    },
    fullRequests: {},
    async getRequest(id) {
      const {
        data: { request },
      } = await $http.get(`/api/quotation/request/${id}`);

      this.fullRequests[id] = request;

      return { request };
    },
    async uploadFile(file) {
      const { data: img } = await $http.post('/api/company/upload-file', file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return { img };
    },
    async updateQuotation(supplierID, quotation) {
      await $http.put(`/api/quotation/update/${supplierID}`, quotation);
    },
  };
}
