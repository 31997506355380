<template>
  <footer
    v-show="$route.name !== 'products'"
    class="footer"
    :class="{ 'footer--suppliers': $route.name === 'footwearSuppliers' || $route.name === 'componentSuppliers' }"
  >
    <div class="footer__container">
      <div class="footer__credits">
        <router-link :to="{ name: 'home' }" class="footer__logo" v-html="svg.logo"></router-link>
        <div class="footer__copy">© FindSourcing 2018</div>
      </div>
      <ul class="footer-menu">
        <li class="footer-menu__item">
          <div class="footer-menu__sub-item">
            <span class="footer-menu__link">About</span>
            <ul class="footer-submenu">
              <li class="footer-submenu__item">
                <router-link :to="{ name: 'about' }" class="footer-submenu__link">About FindSourcing</router-link>
              </li>
              <li class="footer-submenu__item">
                <a href="https://careers.resourced.com" class="footer-submenu__link">Careers</a>
              </li>
              <li class="footer-submenu__item">
                <router-link :to="{ name: 'privacy-policy' }" class="footer-submenu__link">Privacy Policy</router-link>
              </li>
            </ul>
          </div>
        </li>
        <li class="footer-menu__item footer-menu__item--last">
          <div class="footer-menu__sub-item">
            <a href="#" class="footer-menu__link">Get in touch</a>
            <ul class="footer-submenu">
              <li class="footer-submenu__item">
                <a href="mailto:contact@findsourcing.com" class="footer-submenu__link">Contact Us</a>
              </li>
              <ul class="footer-social">
                <li class="footer-social__item">
                  <a
                    href="https://www.facebook.com/FindSourcing/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="footer-social__link"
                    v-html="svg.fb"
                  ></a>
                </li>
                <!-- <li class="footer-social__item">
                      <a href="#" class="footer-social__link" v-html="svg.gplus"></a>
                </li>-->
                <li class="footer-social__item">
                  <a
                    href="https://www.linkedin.com/company/11406930/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="footer-social__link"
                    v-html="svg.linkedIn"
                  ></a>
                </li>
              </ul>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
// SVG
import logo from '../../img/logo-inverted.svg';
import fb from '../../img/fb-icon.svg';
import gplus from '../../img/gplus-icon.svg';
import linkedIn from '../../img/in-icon.svg';

export default {
  data() {
    return {
      svg: {
        logo,
        fb,
        gplus,
        linkedIn,
      },
    };
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
.footer {
  background: $c-dark;
  position: relative;
  z-index: 2;
  &--suppliers {
    @include tablet-landscape-min {
      padding-right: 34%;
    }
  }
  &__container {
    padding: math.div(2.5rem, 1.6) math.div(1.5rem, 1.6) math.div(8rem, 1.6);
    margin: 0 auto;
    max-width: math.div(44rem, 1.6);
    @include tablet-min {
      padding: math.div(4rem, 1.6) math.div(3rem, 1.6) math.div(6rem, 1.6);
    }
  }
  &__credits {
    text-align: center;
  }
  &__logo {
    display: inline-block;
    margin: 0;
  }
  &__copy {
    font-size: math.div(1.1rem, 1.6);
    display: block;
    margin-top: math.div(1rem, 1.6);
    color: rgba($c-light, 0.7);
    letter-spacing: 0.7px;
  }
  &-menu,
  &-submenu {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  &-menu {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include desktop-min {
      flex-wrap: nowrap;
    }
    &__link {
      display: inline-block;
      font-size: math.div(1.2rem, 1.6);
      text-transform: uppercase;
      font-weight: 700;
      color: $c-light;
      letter-spacing: 0.8px;
      margin-top: math.div(3rem, 1.6);
      @include tablet-min {
        font-size: math.div(1.4rem, 1.6);
      }
    }
  }
  &-submenu {
    margin-top: math.div(1rem, 1.6);
    @include tablet-min {
      margin-top: math.div(1.5rem, 1.6);
    }
    &__item {
      margin: math.div(0.5rem, 1.6) 0;
      @include tablet-min {
        margin: math.div(1rem, 1.6) 0;
      }
    }
    &__link {
      font-size: math.div(1.1rem, 1.6);
      font-weight: 700;
      color: rgba($c-light, 0.7);
      letter-spacing: 0.8px;
      transition: color 0.5s ease;
      &:hover {
        color: $c-light;
      }
      @include tablet-min {
        font-size: math.div(1.3rem, 1.6);
      }
    }
  }
  &-social {
    display: flex;
    list-style: none;
    padding: 0;
    margin: math.div(1.5rem, 1.6) 0 0;
    &__item {
      display: block;
      &:not(:first-child) {
        margin-left: math.div(1rem, 1.6);
      }
    }
    &__link {
      display: block;
      width: math.div(2rem, 1.6);
      height: math.div(2rem, 1.6);
      opacity: 0.7;
      transition: opacity 0.5s ease;
      &:hover {
        opacity: 1;
      }
      svg {
        fill: $c-light;
      }
    }
  }
}
</style>
