export default function (store, { $http }) {
  return {
    status: null,
    async checkStatus(query) {
      if (!store.user.loggedIn) return;

      const {
        data: { status },
      } = await $http.get(`/api/subscriptions/check?${query}`);

      this.status = status;
    },
    async toggleSubscription(query) {
      if (!store.user.loggedIn) return;

      await $http.post('/api/subscriptions', { query });
      await this.checkStatus(query);
    },
  };
}
