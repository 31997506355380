export default function (store, { $http }) {
  return {
    async add() {
      await $http.post(`/api/buyers/add`);

      this.$ga.event('user', 'Add buyer company', 'add');

      // Reload user, since the user now have a new buyer connected
      await store.user.checkStatus();
    },
    async save(company) {
      if (!store.user.company) this.$ga.event('user', 'Add buyer company', 'save');

      await $http[store.user.company ? 'put' : 'post'](`/api/buyers`, company);
      await store.user.checkStatus();
    },
    async get(id) {
      const { data: buyer } = await $http.get(`/api/buyers/${id}`);

      return { buyer };
    },
    created() {
      // To force Vue'ification of module, since then we can use this.$ga
    },
  };
}
