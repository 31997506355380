<template>
  <section class="form">
    <h2 class="form__heading">Error: {{ title }}</h2>
    <div class="form__group form__group--message form__group--center">
      <p class="form__subheading">
        {{ message }}
        <template v-if="!fixed && refresh">
          Your browser will automatically be refreshed in <strong>{{ countDown.seconds }}</strong> to ensure what you
          see is up-to-date.</template
        >
        <template v-if="!fixed && !refresh"
          >You will automatically be redirected to the start page in <strong>{{ countDown.seconds }}</strong
          >.</template
        >
      </p>
    </div>
  </section>
</template>

<script>
import countDown from './../lib/timers';

export default {
  load({ store, route }) {
    // TODO: Fix me so that I only trigger when I'm a proper page rendered from SSR
    // store.head.title = `FindSourcing – Error!`;
  },
  components: {},
  props: {
    title: {},
    message: {},
    refresh: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countDown: {},
    };
  },
  methods: {},
  mounted() {
    if (!this.fixed) {
      if (this.refresh) {
        this.countDown = countDown(this, 10, () => {
          location.reload();
        });
      } else {
        this.countDown = countDown(this, 10, () => {
          this.$router.push({
            name: 'home',
          });
        });
      }
    }

    // Temp hack for main padding, only when in page mode (not popup)
    try {
      if (!this.$parent.$parent) document.getElementsByClassName('main')[0].classList.add('main--padding');
    } catch (e) {}
  },
  beforeDestroy() {
    // Temp hack for main padding, only when in page mode (not popup)
    try {
      if (!this.$parent.$parent) document.getElementsByClassName('main')[0].classList.remove('main--padding');
    } catch (e) {}
  },
};
</script>
