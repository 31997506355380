import loadScript from 'load-script';

import countries from './data/countries.json';

export default function (store, { $http }) {
  return {
    full: countries,
    geoJSON: {},
    gmapsInfoBoxLoaded: false,
    gmapsLoaded: null,
    get all() {
      return this.full.map((country) => country.name);
    },
    codeAlpha3FromName(name) {
      return (this.full.find((country) => country.name.toLowerCase() == name.toLowerCase()) || {}).codeAlpha3;
    },
    getFlagPathFromName(name) {
      try {
        return `/static/flags/${this.codeAlpha3FromName(name).toLowerCase()}.svg`;
      } catch (e) {
        console.log(`Failed to get flag icon for country ${name}`);
      }
    },
    async getGeoJSON(codeAlpha3) {
      if (!this.geoJSON[codeAlpha3]) {
        const { data } = await $http.get(`/static/maps/coastlines/${codeAlpha3}.json`);
        this.geoJSON[codeAlpha3] = data;
      }

      return this.geoJSON[codeAlpha3];
    },
    async getMultiPolygonCoordinates(codeAlpha3) {
      const data = await this.getGeoJSON(codeAlpha3);

      if (data.geometry.type === 'MultiPolygon') return data.geometry.coordinates;

      return [data.coordinates];
    },
    loadGeoJSONForGMaps(map, codeAlpha3) {
      return map.data.loadGeoJson(`/static/maps/coastlines/${codeAlpha3}.json`);
    },
    loadGMaps() {
      if (!this.gmapsLoaded) {
        this.gmapsLoaded = new Promise((resolve, reject) => {
          // Due to issues with GMaps not being loaded in time (see here: https://stackoverflow.com/questions/14184956/async-google-maps-api-v3-undefined-is-not-a-function/14185834#14185834
          // for more details), we have to use the global callback (unfortunately).
          // It's assumed nobody else use this name globally, so no care is taken
          // to ensure no conflicts (since nobody else really should use this name).
          window.googleMapsCallback = () => {
            resolve();
          };

          loadScript(
            `//maps.googleapis.com/maps/api/js?key=${APP_CONFIG.gmaps.key}&libraries=places&language=en&callback=googleMapsCallback`
          );
        });
      }

      return this.gmapsLoaded;
    },
    loadGMapsInfoBox() {
      if (this.gmapsInfoBoxLoaded) return Promise.resolve();

      return new Promise((resolve, reject) => {
        loadScript(`/static/google-maps/infobox.js`, () => {
          this.gmapsInfoBoxLoaded = true;
          resolve();
        });
      });
    },
    countryInString(str) {
      for (const country of this.all) {
        if (str.indexOf(country) > -1) {
          return country;
        }
      }

      return null;
    },
  };
}
