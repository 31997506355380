export default function (store, { $http }) {
  return {
    all: null,
    load() {
      return $http.get('/api/category/full').then((response) => {
        this.all = response.data;

        return Promise.resolve(response.data);
      });
    },
  };
}
