import axios from 'axios';
import cookies from 'modernizr-esm/feature/cookies';
import checkIfIE from 'apps/public/lib/checkIfIE';
import Vue from 'vue';
import Minilog from 'minilog';
import { Log } from 'apps/server/log/Log';
import qs from 'qs';

// Create log instance
Minilog.enable();
const log = new Log({ minilog: Minilog('client') });

// Create axios instance/ajax helper
// Fix IE AJAX cache issue (caching stuff it shouldn't)
const axiosOptions = {
  paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
};

if (checkIfIE()) {
  Object.assign(axiosOptions, {
    headers: {
      Pragma: 'no-cache',
    },
  });
}

if (APP_CONFIG.baseURL) {
  axiosOptions.baseURL = APP_CONFIG.baseURL;

  // Assume CORS since baseURL is custom
  axiosOptions.withCredentials = true;
}

const $http = axios.create(axiosOptions);

if (APP_CONFIG.baseURL) {
  // Handle CORS cookies for SSR
  // Basically, we're faking the AspNetCore Identity cookie - we
  // need to have it as a separate name though to avoid name
  // collision with the existing "real" cookie. See
  // server/runner.js for the other side of things.
  $http.interceptors.response.use(function (response) {
    const corsCookie = response.headers['fs-cors-auth-cookie'];

    if (corsCookie) {
      document.cookie = corsCookie
        .replace('.AspNetCore.Identity.Application', 'AspNetCore.Identity.Application')
        .replace('secure;', '')
        .replace('samesite=none', '')
        .replace('httponly', '');
    }

    return response;
  });
}

// Ensure cookies are enabled and working - since we depend on them for lots of things (auth, etc)
var bootError;

function cookieError() {
  bootError = {
    title: 'Cookies disabled',
    message:
      'This website needs cookies enabled and working to function properly. Please enable cookies and then refresh the page.',
    fixed: true,
  };
}

if (!cookies) cookieError();

export function clientFactory({ createApp }) {
  const { app, router, store } = createApp({ log, state: window.__INITIAL_STATE__, bootError, $http });

  // In order to easily test the backend (and store layer), make the store always available from the console
  window.$store = store;

  router.onReady(() => {
    // Add router hook for handling asyncData.
    // Doing it after initial route is resolved so that we don't double-fetch
    // the data that we already have. Using `router.beforeResolve()` so that all
    // async components are resolved.
    router.beforeResolve((to, from, next) => {
      const matched = router.getMatchedComponents(to);
      const prevMatched = router.getMatchedComponents(from);

      // we only care about none-previously-rendered components,
      // so we compare them until the two matched lists differ
      // Also, if same components but different routes - we
      // can't be sure we don't need to reload the data, so
      // reload data in such cases (make everything differ)
      let diffed = !from || to.name !== from.name;
      const activated = matched.filter((c, i) => {
        return diffed || (diffed = prevMatched[i] !== c);
      });

      if (!activated.length) return next();

      Promise.all(
        activated.map((c) => {
          if (c?.load) {
            return c?.load({ store, route: to, router });
          }
        })
      )
        .then(() => {
          next();
        })
        .catch((error) => {
          // API call error due to not being logged in anymore
          if (
            error &&
            error.request &&
            error.request.status &&
            [401, 405].includes(error.request.status) &&
            error.request.responseURL &&
            error.request.responseURL.includes('NotLoggedIn')
          ) {
            // Do full page refresh causing state to be properly updated (and which will
            // redirect to auth page which after successful login will redirect back to
            // the original page.
            location.href = to.fullPath;
            return;
          }

          // Unknown error happened, redirect to home page with full page reload for
          // 100% safe state refresh
          location.href = '';
        });
    });

    router.afterEach((to, from) => {
      // In order to ensure proper history, we need to do it like this - see
      // https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
      Vue.nextTick(() => {
        document.title = store.head.title;
      });
    });

    app.$mount('#app');
  });
}
