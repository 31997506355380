// Inspiration taken from https://github.com/yyx990803/vue-hooks/blob/master/index.js
import Vue from 'vue';

// TODO: Handle component updates
export default function countDown(instance, seconds, callback) {
  const data = Vue.observable({ seconds: seconds });

  const interval = setInterval(() => {
    data.seconds--;
  }, 1000);

  const timeout = setTimeout(() => {
    clearInterval(interval);

    if (callback) callback.call(instance);
  }, seconds * 1000);

  instance.$on('hook:beforeDestroy', () => {
    clearInterval(interval);
    clearTimeout(timeout);
  });

  return data;
}
