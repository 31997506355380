var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { class: { header: true, "header--min": _vm.headerMin } },
    [
      _c(
        "router-link",
        {
          class: {
            header__logo: true,
            "header__logo--is-centered": _vm.menuIsOpened,
          },
          attrs: { to: { name: "home" }, exact: "" },
        },
        [
          _c("strong", {
            domProps: { innerHTML: _vm._s(_vm.icons.singles.logoIcon) },
          }),
        ]
      ),
      _vm._v(" "),
      _vm.menuIsOpened && _vm.submenuIsOpened
        ? _c(
            "a",
            {
              staticClass: "back-button",
              attrs: { href: "#", role: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.menuGoBack()
                },
              },
            },
            [_vm._v("\n    Back\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "menu-button",
          attrs: { href: "#", role: "button" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.toggleMenu()
            },
          },
        },
        [
          _c("span", { staticClass: "menu-button__label" }, [_vm._v(" Menu ")]),
          _vm._v(" "),
          _c(
            "div",
            {
              class: {
                "menu-button__icon": true,
                "menu-button__icon--is-active": _vm.menuIsOpened,
              },
            },
            [_c("span"), _vm._v(" "), _c("span"), _vm._v(" "), _c("span")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            "menu-container": true,
            "menu-container--is-opened": _vm.menuIsOpened,
          },
        },
        [
          _vm.user
            ? _c(
                "ul",
                {
                  class: { menu: true, "menu--is-hidden": _vm.submenuIsOpened },
                },
                [
                  _c(
                    "li",
                    { staticClass: "menu__item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "menu__link",
                          attrs: {
                            to: {
                              name: _vm.$store.user.isBuyer
                                ? "quotations"
                                : "quotationOverview",
                            },
                          },
                        },
                        [_vm._v("\n          Quotation Requests\n        ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "menu__item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "menu__link",
                          attrs: { to: { name: "suppliers" } },
                        },
                        [_vm._v(" Suppliers ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "menu__item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "menu__link menu__link--tablet",
                          attrs: { to: { name: "articles" } },
                        },
                        [_vm._v(" Knowledge bank ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "menu__link menu__link--mobile menu__link--is-parent",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openCategoriesMenu($event)
                            },
                          },
                        },
                        [_vm._v("\n          Knowledge bank\n        ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.$store.user.isBuyer
                    ? _c(
                        "li",
                        { staticClass: "menu__item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "menu__link",
                              attrs: {
                                to: { name: "quotationForm" },
                                exact: "",
                              },
                            },
                            [_vm._v("Submit Quotation Request")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "menu__item menu__item--right" },
                    [
                      !_vm.user.companies.length
                        ? _c(
                            "router-link",
                            {
                              staticClass: "menu__link",
                              staticStyle: { "margin-right": "0" },
                              attrs: { to: { name: "addCompany" } },
                            },
                            [_vm._v("\n          Add company\n        ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          class: {
                            menu__link: true,
                            "menu__link--is-parent": true,
                            "menu__link--mobile": true,
                            "is-active": _vm.profileMenuIsOpened,
                          },
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.toggleProfileMenu()
                            },
                          },
                        },
                        [_vm._v("\n          Profile\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          class: {
                            menu__link: true,
                            "menu__link--big-icon": true,
                            "menu__link--tablet": true,
                            "is-active": _vm.profileMenuIsOpened,
                          },
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.toggleProfileMenu()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s((_vm.$store.user.company || {}).name) +
                              "\n          "
                          ),
                          !_vm.user.photo
                            ? _c("span", {
                                staticClass: "menu__link menu__link-icon",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.icons.singles.accountIcon
                                  ),
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.user.photo
                            ? _c(
                                "span",
                                {
                                  staticClass: "menu__link menu__link-icon",
                                  style: {
                                    backgroundImage:
                                      "url(" + _vm.userPhoto + ")",
                                  },
                                },
                                [_c("img", { attrs: { src: _vm.userPhoto } })]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _c(
                "ul",
                {
                  class: { menu: true, "menu--is-hidden": _vm.submenuIsOpened },
                },
                [
                  _c(
                    "li",
                    { staticClass: "menu__item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "menu__link",
                          attrs: { to: { name: "footwearSuppliers" } },
                        },
                        [_vm._v(" Footwear Suppliers ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "menu__item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "menu__link",
                          attrs: { to: { name: "componentSuppliers" } },
                        },
                        [_vm._v(" Component Suppliers ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "menu__item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "menu__link menu__link--tablet",
                          attrs: { to: { name: "articles" } },
                        },
                        [_vm._v(" Knowledge bank ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass:
                            "menu__link menu__link--mobile menu__link--is-parent",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openCategoriesMenu($event)
                            },
                          },
                        },
                        [_vm._v("\n          Knowledge bank\n        ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "menu__item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "menu__link",
                          attrs: { to: { name: "quotation" }, exact: "" },
                        },
                        [_vm._v(" Submit Quotation Request ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "menu__item menu__item--right" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "menu__link menu__link--special-hide",
                          attrs: {
                            to: { name: "supplierRegister" },
                            exact: "",
                          },
                        },
                        [
                          _vm._v(
                            "\n          Join as a Factory/Agent\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "menu__link",
                          attrs: { event: "", to: { name: "login" } },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.$popover.open("logIn")
                            },
                          },
                        },
                        [_vm._v("\n          Login\n        ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
          _vm._v(" "),
          _c(
            "ul",
            {
              class: {
                "menu menu--categories": true,
                "menu--is-active": _vm.categoriesMenuIsOpened,
                "menu--is-hidden": _vm.articlesMenuIsOpened,
              },
            },
            _vm._l(_vm.categories, function (category, i) {
              return _c(
                "li",
                { key: "category" + i, staticClass: "menu__item" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "menu__link menu__link--is-parent",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.openArticlesMenu(i)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(category.title) + "\n        "
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "ul",
            {
              class: {
                menu: true,
                "menu--articles": true,
                "menu--is-active": _vm.articlesMenuIsOpened,
              },
            },
            _vm._l(_vm.activeCategory.articles, function (article, i) {
              return _c(
                "li",
                { key: "article" + i, staticClass: "menu__item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "menu__link",
                      attrs: {
                        to: {
                          name: "article",
                          params: {
                            category: _vm.activeCategory.slug,
                            slug: article.slug,
                          },
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(article.title) + "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(article.subArticles, function (subArticle) {
                    return _c(
                      "router-link",
                      {
                        key: subArticle.id,
                        staticClass: "menu__link menu__link--is-child",
                        attrs: {
                          to: {
                            name: "article",
                            params: {
                              category: _vm.activeCategory.slug,
                              slug: subArticle.slug,
                            },
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(subArticle.title) +
                            "\n        "
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.user
            ? _c(
                "div",
                {
                  class: {
                    "dropdown-menu": true,
                    "dropdown-menu--is-active": _vm.profileMenuIsOpened,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-menu__section dropdown-menu__profile",
                    },
                    [
                      !_vm.user.photo
                        ? _c("span", {
                            staticClass: "dropdown-menu__profile-image",
                            domProps: {
                              innerHTML: _vm._s(_vm.icons.singles.accountIcon),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.user.photo
                        ? _c("span", {
                            staticClass: "dropdown-menu__profile-image",
                            style: {
                              backgroundImage: "url(" + _vm.userPhoto + ")",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("router-link", {
                        staticClass: "dropdown-menu__profile-edit",
                        attrs: { to: { name: "editProfile" } },
                        domProps: {
                          innerHTML: _vm._s(_vm.icons.singles.editIcon),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "strong",
                        {
                          class: {
                            "dropdown-menu__profile-name": true,
                            "dropdown-menu__profile-name--no-social": true,
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.user.name || "<Your name>") +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "dropdown-menu__profile-email" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.user.userName) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      false
                        ? _c(
                            "div",
                            { staticClass: "dropdown-menu__profile-social" },
                            [
                              _c("a", {
                                staticClass:
                                  "dropdown-menu__profile-social-icon",
                                attrs: { href: "#" },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.icons.singles.linkedinIcon
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("a", {
                                staticClass:
                                  "dropdown-menu__profile-social-icon",
                                attrs: { href: "#" },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.icons.singles.linkedinIcon
                                  ),
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-menu__section dropdown-menu__section--companies",
                    },
                    _vm._l(_vm.user.companies, function (company) {
                      return _c(
                        "div",
                        {
                          key: company.id,
                          class: {
                            "dropdown-menu__company": true,
                            "is-selectable": _vm.user.companies.length > 1,
                            "is-selected":
                              _vm.$store.user.isSelectedCompany(company),
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "dropdown-menu__company-content-group",
                            },
                            [
                              _c(
                                "strong",
                                {
                                  staticClass: "dropdown-menu__company-header",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(company.name) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              company.email
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "dropdown-menu__company-email",
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(company.email) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !company.email
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "dropdown-menu__company-email",
                                    },
                                    [_c("i", [_vm._v("No e-mail registered")])]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "dropdown-menu__company-action-group",
                            },
                            [
                              company.type !== 1
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "dropdown-menu__company-link",
                                      attrs: {
                                        to: {
                                          name: "supplier",
                                          params: { name: company.slug },
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "dropdown-menu__company-link-icon",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.icons.singles.descriptionIcon
                                          ),
                                        },
                                      }),
                                      _vm._v(
                                        "\n              View\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-menu__company-link",
                                  attrs: {
                                    to:
                                      company.type === 1
                                        ? { name: "quotationForm" }
                                        : {
                                            name: "supplierEdit",
                                            params: { id: company.id },
                                          },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass:
                                      "dropdown-menu__company-link-icon",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.icons.singles.editIcon
                                      ),
                                    },
                                  }),
                                  _vm._v("\n              Edit\n            "),
                                ]
                              ),
                              _vm._v(" "),
                              false
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "dropdown-menu__company-link",
                                      attrs: {
                                        to: {
                                          name: "supplier",
                                          params: { name: company.slug },
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "dropdown-menu__company-link-icon",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.icons.singles.delIcon
                                          ),
                                        },
                                      }),
                                      _vm._v(
                                        "\n              Remove\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-menu__section dropdown-menu__section--right-aligned",
                    },
                    [
                      _vm.$store.user.isPlm
                        ? _c(
                            "a",
                            {
                              staticClass: "dropdown-menu__action",
                              attrs: { href: "/plm/products" },
                            },
                            [_vm._v(" PLM ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$store.user.isAdmin
                        ? _c(
                            "a",
                            {
                              staticClass: "dropdown-menu__action",
                              attrs: { href: "/admin" },
                            },
                            [_vm._v(" Admin ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      false
                        ? _c(
                            "router-link",
                            {
                              staticClass: "dropdown-menu__action",
                              attrs: { to: { name: "login" } },
                            },
                            [_vm._v(" Add company ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-menu__action",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.logOut()
                            },
                          },
                        },
                        [_vm._v(" Logout ")]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.progressBar
        ? _c("div", {
            staticClass: "progress-bar",
            style: { width: _vm.progressBarWidth + "%" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "popover",
        { attrs: { name: "logIn" } },
        [
          _c("LogIn", {
            attrs: {
              mode: "popup",
              email: _vm.email,
              password: _vm.password,
              toggled: _vm.toggled,
            },
            on: {
              "update:email": function ($event) {
                _vm.email = $event
              },
              "update:password": function ($event) {
                _vm.password = $event
              },
              "update:toggled": function ($event) {
                _vm.toggled = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "popover",
        { attrs: { name: "signUp" } },
        [
          _c("SignUp", {
            attrs: {
              mode: "popup",
              email: _vm.email,
              password: _vm.password,
              toggled: _vm.toggled,
            },
            on: {
              "update:email": function ($event) {
                _vm.email = $event
              },
              "update:password": function ($event) {
                _vm.password = $event
              },
              "update:toggled": function ($event) {
                _vm.toggled = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "popover",
        { attrs: { name: "forgotPassword" } },
        [
          _c("ForgotPassword", {
            attrs: { mode: "popup", email: _vm.email },
            on: {
              "update:email": function ($event) {
                _vm.email = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$store.user.needMoreInfo
        ? _c(
            "popover",
            {
              attrs: {
                name: "moreInfo",
                force: "",
                overlayClose: false,
                escClose: false,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return undefined
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3307960630
              ),
            },
            [_vm._v(" "), _c("MoreInfo")],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }