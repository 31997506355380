var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "form" }, [
    _c("h2", { staticClass: "form__heading" }, [
      _vm._v("Error: " + _vm._s(_vm.title)),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form__group form__group--message form__group--center" },
      [
        _c(
          "p",
          { staticClass: "form__subheading" },
          [
            _vm._v("\n      " + _vm._s(_vm.message) + "\n      "),
            !_vm.fixed && _vm.refresh
              ? [
                  _vm._v(
                    "\n        Your browser will automatically be refreshed in "
                  ),
                  _c("strong", [_vm._v(_vm._s(_vm.countDown.seconds))]),
                  _vm._v(" to ensure what you\n        see is up-to-date."),
                ]
              : _vm._e(),
            _vm._v(" "),
            !_vm.fixed && !_vm.refresh
              ? [
                  _vm._v(
                    "You will automatically be redirected to the start page in "
                  ),
                  _c("strong", [_vm._v(_vm._s(_vm.countDown.seconds))]),
                  _vm._v("."),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }