const Home = () => import('pages/home.vue');
const Suppliers = () => import('pages/suppliers.vue');
const FootwearSuppliers = () => import('pages/suppliers/footwear.vue');
const Supplier = () => import('pages/supplier/index.vue');
const SupplierOld = () => import('pages/supplier/index-old.vue');
const SupplierEdit = () => import('pages/supplier-edit.vue');
const SupplierAdd = () => import('pages/supplier-add.vue');
const SupplierRegister = () => import('pages/supplier-register.vue');
const AddCompany = () => import('pages/add-company.vue');
const EditBuyerCompany = () => import('pages/edit-buyer-company.vue');
const Articles = () => import('pages/articles.vue');
const Article = () => import('pages/article.vue');
const Page = () => import('pages/page.vue');

const Quotation = () => import('pages/quotation.vue');
const QuotationForm = () => import('pages/quotation-form.vue');
const QuotationOverview = () => import('pages/quotation-overview.vue');
const QuotationIntro = () => import('pages/quotation-intro.vue');
const QuotationInfo = () => import('pages/quotation-info.vue');

const Quotations = () => import('pages/quotations/index.vue');
const QuotationsRequest = () => import('pages/quotations/request/index.vue');

const LogIn = () => import('pages/account/log-in.vue');
const SignUp = () => import('pages/account/sign-up.vue');
const ForgotPassword = () => import('pages/account/forgot-password.vue');
const ResetPassword = () => import('pages/account/reset-password.vue');
const MoreInfo = () => import('pages/account/more-info.vue');

const ConfirmEmail = () => import('pages/account/confirm-email.vue');
const ConfirmInvite = () => import('pages/account/confirm-invite.vue');
const ConfirmInviteNewUser = () => import('pages/account/confirm-invite-new-user.vue');
const ConfirmSupplierUserConnection = () => import('pages/account/confirm-supplier-user-connection.vue');
const ConfirmSuppliersEmail = () => import('pages/account/confirm-suppliers-email.vue');
const ExternalLoginCallback = () => import('pages/account/external-login-callback.vue');
const EditProfile = () => import('pages/account/edit-profile.vue');

const Vegas = () => import('pages/vegas.vue');

export const routes = [
  // Home
  {
    name: 'home',
    path: '/',
    component: Home,
  },

  // Pages
  {
    name: 'about',
    path: '/about',
    component: Page,
    meta: {
      pageType: 'generalPage',
    },
  },
  {
    name: 'privacy-policy',
    path: '/privacy-policy',
    component: Page,
    meta: {
      pageType: 'generalPage',
    },
  },

  // Articles
  {
    name: 'articles',
    path: '/articles',
    component: Articles,
    meta: {
      pageType: 'articles',
    },
  },

  {
    name: 'article',
    path: '/articles/:category/:slug',
    component: Article,
    meta: {
      pageType: 'article',
    },
  },

  // Suppliers
  {
    name: 'footwearSuppliers2',
    path: '/suppliers/footwear2',
    component: FootwearSuppliers,
    meta: {
      pageType: 'suppliers',
      category: 0,
    },
  },
  {
    name: 'footwearSuppliers',
    path: '/suppliers/footwear',
    component: Suppliers,
    meta: {
      pageType: 'suppliers',
      category: 0,
    },
  },
  {
    name: 'componentSuppliers',
    path: '/suppliers/component',
    component: Suppliers,
    meta: {
      pageType: 'suppliers',
      category: 1,
    },
  },
  {
    name: 'suppliers',
    path: '/suppliers',
    redirect: { name: 'footwearSuppliers' },
  },

  {
    name: 'supplierRegister',
    path: '/supplier/register',
    component: SupplierRegister,
  },
  {
    name: 'supplierAdd',
    path: '/supplier/add',
    component: SupplierAdd,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'supplierEdit',
    path: '/supplier/edit/:id',
    component: SupplierEdit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'supplierProfile', // Deliberately not named supplier to avoid CSS conflicts, since the route name is used as base class on the whole view
    path: '/supplier/:name(design-shoes-colection-srl|okabashi-brands|valuni--eurodavil|texon|pittards-plc|t-k-shoes-factory|yi-sheng-leather-co-ltd|litian-textile-technology-co-ltd|viet-star-viet-nam-co-ltd|freecode-technology-coltd|hong-chen-vietnam-co-ltd|yin-hwa-precision-lasts-co-ltd)', // Temp path with single supplier until we've completed the new profile code for all cases
    component: Supplier,
    meta: {
      pageType: 'supplier',
    },
  },
  {
    name: 'supplier',
    path: '/supplier/:name',
    component: SupplierOld,
    meta: {
      pageType: 'supplier',
    },
  },

  // Account
  {
    name: 'login',
    path: '/account/login',
    component: LogIn,
  },
  {
    name: 'externalLogin',
    path: '/account/externalLoginCallback',
    component: ExternalLoginCallback,
  },
  {
    name: 'signUp',
    path: '/account/signUp',
    component: SignUp,
  },
  {
    name: 'forgotPassword',
    path: '/account/forgotPassword',
    component: ForgotPassword,
  },
  {
    name: 'confirmEmail',
    path: '/account/confirmEmail',
    component: ConfirmEmail,
  },
  {
    name: 'confirmInvite',
    path: '/account/confirmInvite',
    component: ConfirmInvite,
  },
  {
    name: 'confirmInviteNewUser',
    path: '/account/confirmInviteNewUser',
    component: ConfirmInviteNewUser,
  },
  {
    name: 'confirmSupplierUserConnection',
    path: '/account/confirmSupplierUserConnection',
    component: ConfirmSupplierUserConnection,
  },
  {
    name: 'resetPassword',
    path: '/account/resetPassword',
    component: ResetPassword,
  },
  {
    name: 'moreInfo',
    path: '/account/moreInfo',
    component: MoreInfo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'setPassword',
    path: '/account/setPassword',
    component: ResetPassword,
  },
  {
    name: 'confirmSuppliersEmail',
    path: '/account/confirmSuppliersEmail',
    component: ConfirmSuppliersEmail,
  },
  {
    name: 'editProfile',
    path: '/account/editProfile',
    component: EditProfile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'addCompany',
    path: '/company/add',
    component: AddCompany,
  },
  {
    name: 'editBuyerCompany',
    path: '/company/edit',
    component: EditBuyerCompany,
  },

  {
    name: 'quotation',
    path: '/quotation',
    component: Quotation,
    meta: {
      supplier: 'quotationOverview',
    },
  },
  {
    name: 'quotationForm',
    path: '/quotation/request',
    component: QuotationForm,
    meta: {
      requiresAuth: true,
      supplier: 'quotationOverview', // Temporary fix until we've figured out multi company support and a better registration flow
    },
  },
  {
    name: 'quotationOverview',
    path: '/quotation/overview',
    component: QuotationOverview,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'quotationIntro',
    path: '/quotation/intro/:id',
    component: QuotationIntro,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'quotationInfo',
    path: '/quotation/info/:id',
    component: QuotationInfo,
    meta: {
      requiresAuth: true,
    },
  },
  // New quotation pages
  {
    name: 'quotations',
    path: '/quotations',
    component: Quotations,
    meta: {
      requiresAuth: true,
      supplier: 'quotationOverview',
    },
  },
  {
    name: 'quotations-request',
    path: '/quotations/request/:id',
    component: QuotationsRequest,
    meta: {
      requiresAuth: true,
      supplier: 'quotationOverview',
    },
  },
  {
    name: 'vegas',
    path: '/vegas',
    component: Vegas,
  },

  {
    name: 'satra',
    path: '/satra',
    redirect: { name: 'supplierRegister', query: { satra: true } },
  },

  // All
  {
    path: '*',
    redirect: { name: 'home' },
  },
];
