export default function (store, { $http }) {
  return {
    loaded: null,
    get isLoaded() {
      return this.loaded !== null;
    },
    allSubTypes() {
      if (!this.loaded) {
        throw new Error('ComponentTypes have not been loaded yet.');
      }

      return this.loaded.map((t) => t.subTypes).flat();
    },
    async ensureAllLoaded() {
      if (!this.isLoaded) {
        return await this.loadAll();
      }

      return this.loaded;
    },
    async loadAll() {
      const brandId = store.user.brandId;

      const { data: componentTypes } = await $http.get(`/api/brand/${brandId}/components/types`);
      this.loaded = componentTypes;

      return { componentTypes };
    },

    async addType(name) {
      const brandId = store.user.brandId;

      const existingTypeWithSameName = this.loaded?.find((type) => type.name.toLowerCase() === name.toLowerCase());

      if (existingTypeWithSameName) {
        return existingTypeWithSameName;
      }

      const { data: componentType } = await $http.post(`/api/brand/${brandId}/components/types`, { name });

      this.loaded.push(componentType);
      this.loaded.sort((a, b) => a.name.localeCompare(b.name));

      return componentType;
    },

    async addSubType(typeId, name) {
      const brandId = store.user.brandId;

      const componentType = this.loaded?.find((type) => type.id === typeId);

      const existingSubTypeWithSameName = componentType?.subTypes.find((st) => st.name === name);

      if (existingSubTypeWithSameName) {
        return componentType;
      }

      const { data: updatedComponentType } = await $http.post(
        `/api/brand/${brandId}/components/types/${typeId}/subtypes`,
        { name }
      );

      this.loaded = this.loaded.map((t) => (t.id !== updatedComponentType.id ? t : updatedComponentType));

      return updatedComponentType;
    },

    getSubTypeFromId(id) {
      if (!this.loaded) {
        throw new Error('ComponentTypes have not been loaded yet.');
      }

      if (id === null) return null;

      return this.loaded
        .map((t) => t.subTypes)
        .flat()
        .find((ct) => ct.id === id);
    },
  };
}
