export default function (store, { $http }) {
  return {
    current: null,
    get isAdmin() {
      return this.current?.roles?.some((r) => r == 'Admin');
    },
    get loggedIn() {
      return !!this.current;
    },
    async checkStatus() {
      try {
        let response = await $http.get('/api/manage/getUserData');

        this.current = response.data;
      } catch (e) {
        this.current = null;
      }
    },
    async logIn(data, companyGuid) {
      let response = await $http.post(`/api/account/login${companyGuid ? '?companyGuid=' + companyGuid : ''}`, data);
      let status = response.data.status;

      if (status === 1) this.current = response.data.user;

      if (this.isPlm) {
        if (process.env.NODE_ENV === 'development') {
          window.location.href = 'http://localhost:1337/plm/products';
        } else {
          window.location.href = '/plm/products';
        }
      }

      if (this.isAdmin) {
        window.location.href = '/admin';
      }

      return status;
    },
    async logOut() {
      await $http.post('/api/account/logout');

      this.current = null;
    },
  };
}
