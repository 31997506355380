import codeOfConduct from './data/code-of-conduct.json';
import typeOfProduction from './data/type-of-production.json';

export default function (store, { $http }) {
  return {
    all: {},
    list: {},
    coordinates: {},
    counter: null,
    filters: {},
    codeOfConduct: codeOfConduct,
    typeOfProduction: typeOfProduction,
    types: ['Manufacturer', 'Buyer', 'Agent', 'Trading company', 'Sourcing company'], // TODO: Move to company store?? We use Manufacturer rather than Supplier though, so needs to be thought through
    loadById(id) {
      return $http.get(`/api/suppliers/id/${id}`).then((response) => {
        this.all[id] = response.data;

        return Promise.resolve(response.data);
      });
    },
    loadBySlug(name) {
      return $http.get(`/api/suppliers/slug/${name}`).then((response) => {
        this.all[name] = response.data;

        return Promise.resolve(response.data);
      });
    },
    loadList(url) {
      return $http.get(url).then((response) => {
        this.list[url] = response.data;
        return Promise.resolve(response.data);
      });
    },
    loadListNextPage(url) {
      let nextPage = this.list[url].currentPage + 1;
      if (nextPage > this.list[url].totalPages) return;
      return $http.get(`${url}&page=${nextPage}`).then((response) => {
        if (response.data.list.length) {
          this.list[url].list = this.list[url].list.concat(response.data.list);
          this.list[url].currentPage = nextPage;
        }
        return Promise.resolve(response.data.list);
      });
    },
    loadCoordinates(url) {
      return $http.get(url).then((response) => {
        this.coordinates[url] = response.data;
        return Promise.resolve(response.data);
      });
    },
    loadFilters() {
      return $http.get('/api/suppliers/filters').then((response) => {
        this.filters = response.data;
        return Promise.resolve(response.data);
      });
    },
    count() {
      return $http.get(`/api/suppliers/count`).then((response) => {
        this.counter = response.data;
        return Promise.resolve(response.data);
      });
    },
    addSupplier() {
      return $http
        .post(`/api/suppliers`, {
          supplier: {
            draft: true,
          },
        })
        .then((resp) => {
          const data = resp.data;

          if (data.status !== 3) throw 'Error!';

          // Reload user, since the user now have a new supplier connected
          return store.user.checkStatus().then(() => {
            this.$ga.event('user', 'Add supplier company', 'registerSupplierWithoutPassword');

            return { id: data.id };
          });
        });
    },
    async update(data) {
      let supplier = data.supplier;

      // Remove relation properties which does no longer strictly relate to
      // Supplier type, but to Company type. Once we rework this, it should
      // not longer be any problems and should be removed as TODO.
      supplier = JSON.parse(JSON.stringify(supplier));

      supplier.footwearTypes = [];
      supplier.materials = [];
      supplier.lastingMethods = [];
      supplier.processes = [];
      supplier.niche = [];
      supplier.capabilities = [];

      supplier.gallery = [];
      supplier.suppliers = [];
      supplier.customers = [];

      supplier.users = [];

      // Ensure booleans and ints
      supplier.materialExpertiseLeather = !!supplier.materialExpertiseLeather;
      supplier.materialExpertiseSynthetic = !!supplier.materialExpertiseSynthetic;
      supplier.materialExpertiseTextile = !!supplier.materialExpertiseTextile;
      supplier.men = !!supplier.men;
      supplier.ladies = !!supplier.ladies;
      supplier.kids = !!supplier.kids;
      supplier.babies = !!supplier.babies;

      supplier.moq = supplier.moq || 0;
      supplier.sizeRangeFrom = supplier.sizeRangeFrom || 0;
      supplier.sizeRangeTo = supplier.sizeRangeTo || 0;
      supplier.dailyProductionFrom = supplier.dailyProductionFrom || 0;
      supplier.dailyProductionTo = supplier.dailyProductionTo || 0;

      data.supplier = supplier;

      let response = await $http.put(`/api/suppliers`, data);
      const status = response.data.status;

      if (status !== 0) await store.user.checkStatus();

      this.$ga.event('suppliers', 'Update supplier', 'update');

      return status;
    },
    async claimSupplier(userEmail, supplierName) {
      const {
        data: { status },
      } = await $http.post('/api/suppliers/claim', { userEmail, supplierName });
      const success = status == 1;

      return { success };
    },
    async getOverview(id) {
      const { data } = await $http.get(`/api/suppliers/id/overview/${id}`);

      return { data };
    },
    async search(query, category) {
      const params = { query, category };
      const { data: suppliers } = await $http.get('/api/suppliers/search', { params });

      return { suppliers };
    },
    async uploadCoverPhoto(file, name, progress) {
      return await this.uploadPhoto(`/api/suppliers/cover`, file, name, progress);
    },
    async uploadGalleryImage(file, progress) {
      return await this.uploadPhoto(`/api/suppliers/image`, file, null, progress);
    },
    // TODO: REFACTOR into something common? See also users and companies
    async uploadPhoto(url, file, name, progress) {
      const fd = new FormData();

      if (name) {
        fd.append('image', file, name);
      } else {
        fd.append('image', file);
      }

      const { data: fileName } = await $http.post(url, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: !progress
          ? null
          : function (progressEvent) {
              let percentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));

              progress(percentage);
            },
      });

      return { fileName };
    },
    async addCustomer(supplier, suppliersIds) {
      try {
        const {
          data: { status, id },
        } = await $http.post(`/api/suppliers/customer`, { supplier, suppliersIds });

        return { status, id };
      } catch (error) {
        console.log(error);
      }
    },
    async simpleSearch(searchTerm, count = null, offset = 0) {
      const { data: suppliers } = await this.$http.get('/api/suppliers/simplesearch', {
        params: { owningBrandId: store.user.brandId, searchTerm, offset, count },
      });

      return { suppliers };
    },
    async simpleAdd(newSupplier) {
      // const inSupplier = {
      //   name: 'CBL albano',
      //   codeAlpha3: 'PRT' // Portugal - see $store.countries.full for a complete list of all countries (names + alpha3 codes)
      // };

      if (!newSupplier.brandId) newSupplier.brandId = store.user.brandId;

      const {
        data: { supplier, nameTaken },
      } = await $http.post(`/api/suppliers/simple`, newSupplier);

      // console.log('simpleAdd() supplier: ', supplier);
      // console.log('simpleAdd() nameTaken: ', nameTaken);

      return { supplier, nameTaken };
    },
    isManufacturer(type) {
      return type === 0;
    },
    offersX(ids, capabilities) {
      if (!capabilities) return;

      const capabilityIds = capabilities.split(',');

      return ids.find((id) => capabilityIds.includes(id));
    },
    offersPreDevelopmentSupport(capabilities) {
      return this.offersX(['1', '2', '3'], capabilities);
    },
    offersDevelopmentSupport(capabilities) {
      return this.offersX(['4', '5', '6', '7'], capabilities);
    },
    offersProduction(capabilities) {
      return this.offersX(['8', '9', '10', '11'], capabilities);
    },
    offersShipping(capabilities) {
      return this.offersX(['12'], capabilities);
    },
    created() {
      // To force Vue'ification of module, since then we can use this.$ga
    },
  };
}
