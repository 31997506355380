import qs from 'apps/public/lib/qs';

export default function (store, { $http }) {
  return {
    main: [],
    exchangeRates: [],
    get fallback() {
      return this.main.find((currency) => currency.code === 'USD');
    },
    async loadMainCurrencies({ exchangeRates } = { exchangeRates: true }) {
      const {
        data: { currencies, exchangeRates: exchangeRatesData },
      } = await $http.get(`/api/localization/main-currencies${qs({ exchangeRates })}`);

      this.main = currencies;
      this.exchangeRates = exchangeRatesData;

      return {
        currencies,
        exchangeRates: exchangeRatesData,
      };
    },
    convert(val, from, to) {
      if (from.code) from = from.code;
      if (to.code) to = to.code;

      const exchangeRate = this.exchangeRates.find((exchangeRate) => {
        if (
          (exchangeRate.from.code === from && exchangeRate.to.code === to) ||
          (exchangeRate.from.code === to && exchangeRate.to.code === from)
        )
          return true;
      });

      if (!exchangeRate) return val; // TODO: REVIEW if we want to throw an error or something instead if we can't find the proper exchangeRate

      // Do the conversion
      if (exchangeRate.from.code === from) {
        return val * exchangeRate.exchangeRate;
      } else {
        // Reverse
        return val / exchangeRate.exchangeRate;
      }
    },
  };
}
