export default function (store, { $http }) {
  return {
    loading: false,
    loadingLocked: false,
    isDragging: false,
    plmLoadingQueue: [],
    prod: process.env.NODE_ENV === 'production',
    satra: false,

    get plmLoading() {
      return Boolean(this.plmLoadingQueue.length);
    },

    // TODO: Consider setting this based on env
    get showFeaturesInProgress() {
      // Set to true to see features currently under development
      const showNonReleasedFeatures = false;

      return showNonReleasedFeatures && !this.prod;
    },

    loadingLock() {
      this.loadingLocked = true;

      return () => (this.loadingLocked = false);
    },

    startPlmLoading() {
      // Never wait for more than 60 seconds, then assume something went wrong
      const timeoutId = setTimeout(() => {
        throw new Error('PLM loading never stopped!');
      }, 60 * 1000);

      const stop = () => {
        clearTimeout(timeoutId);
        this.plmLoadingQueue = this.plmLoadingQueue.filter((fn) => fn !== stop);
      };

      this.plmLoadingQueue.push(stop);

      return stop;
    },
  };
}
