/**
 * Return an imgproxy url to an image of requested size.
 *
 * @param {String}   imagePath    Path to the image. The absolute path from the blob storage root.
 * @param {Number}   width        Width of the image in pixels..
 * @param {Number}   height       Height of the image in pixels.
 * @param {String}   resizeOption 'fill' or 'fit'.
 *
 * See https://imgproxy.net/#demo for how resizeOption 'fill' differs from 'fit'.
 * Fit will add empty space around the picture without cropping
 * Fill will crop the image to given with and height without empty space
 */
export function imagePath(imagePath, width, height, resizeOption = 'fill') {
  if (typeof width !== 'number' || typeof height !== 'number' || !imageFormatSupported(imagePath)) {
    return `${APP_CONFIG.blobUrl}/${imagePath}`;
  }

  return `${APP_CONFIG.imgproxyUrl}/resize:${resizeOption}:${width}:${height}:1/plain/abs://${imagePath}`;
}

function imageFormatSupported(imagePath) {
  if (!imagePath) {
    return false;
  }

  const supportedExtensions = ['png', 'jpg', 'jpeg', 'gif', 'tiff', 'bmp', 'svg', 'heic', 'avif', 'ico'];

  var splitName = imagePath.split('.');

  return supportedExtensions.includes(splitName[splitName.length - 1].toLowerCase());
}
