import addCircle from '@icons/other/add-circle.svg';
import arrowCircleLeft from '@icons/other/arrow-circle-left.svg';
import arrowDown from '@icons/other/arrow-down.svg';
import arrowLeft from '@icons/other/arrow-left.svg';
import arrowRight from '@icons/other/arrow-right.svg';
import calendarCheckmark from '@icons/interface/calendar-checkmark.svg';
import cancel from '@icons/other/cancel.svg';
import checkMark from '@icons/other/check-mark-icon.svg';
import circleArrowBack from '@icons/interface/circle-arrow-back.svg';
import clock from '@icons/interface/clock.svg';
import clockRoundArrow from '@icons/interface/clock-round-arrow.svg';
import cornerBottomLeft from '@icons/interface/corner-bottom-left.svg';
import cornerBottomRight from '@icons/interface/corner-bottom-right.svg';
import colorFilled from '@icons/other/color-filled.svg';
import crossClear from '@icons/other/cross-clear.svg';
import copyOutline from '@icons/interface/copy-outline.svg';
import download from '@icons/interface/download.svg';
import dragNDropVertically from '@icons/interface/drag-n-drop-vertically.svg';
import icoSelected from '@icons/other/ico-selected.svg';
import kebabDots from '@icons/other/kebab-dots-icon.svg';
import pencilIcon from '@icons/other/pencil-icon.svg';
import pin from '@icons/interface/pin.svg';
import planeTable from '@icons/interface/plane-table.svg';
import plusCircleFilledIcon from '@icons/other/plus-circle-filled-icon.svg';
import plusInCircleIcon from '@icons/other/plus-in-circle-icon.svg';
import plusIcon from '@icons/other/plus-icon.svg';
import plusThick from '@icons/interface/plus-thick.svg';
import refreshCircleArrows from '@icons/interface/refresh-circle-arrows.svg';
import reloadDouble from '@icons/interface/reload-double.svg';
import save from '@icons/other/save.svg';
import saveFloppySmall from '@icons/interface/save-floppy-small.svg';
import settings from '@icons/other/settings-icon.svg';
import settingsLined from '@icons/interface/settings-lined.svg';
import sortDown from '@icons/interface/sort-down.svg';
import spinnerDrop from '@icons/interface/spinner-drop.svg';
import thinClose from '@icons/interface/thin-close.svg';
import threeDirectionsFlow from '@icons/interface/three-directions-flow.svg';
import trashCanIcon from '@icons/other/trash-can-icon.svg';
import trashCanOutlined from '@icons/interface/trash-can-outlined.svg';
import uploadCloudArrow from '@icons/interface/upload-cloud-arrow.svg';

export default {
  addCircle,
  arrowCircleLeft,
  arrowDown,
  arrowLeft,
  arrowRight,
  calendarCheckmark,
  cancel,
  checkMark,
  circleArrowBack,
  clock,
  clockRoundArrow,
  cornerBottomLeft,
  cornerBottomRight,
  colorFilled,
  crossClear,
  copyOutline,
  download,
  dragNDropVertically,
  icoSelected,
  kebabDots,
  pencilIcon,
  pin,
  planeTable,
  plusCircleFilledIcon,
  plusInCircleIcon,
  plusIcon,
  plusThick,
  refreshCircleArrows,
  reloadDouble,
  save,
  saveFloppySmall,
  settings,
  settingsLined,
  sortDown,
  spinnerDrop,
  thinClose,
  threeDirectionsFlow,
  trashCanIcon,
  trashCanOutlined,
  uploadCloudArrow,
};
