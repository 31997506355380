import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './routes';

Vue.use(VueRouter);

const restrictedReturnRoutesNames = ['resetPassword', 'login', 'signUp', 'externalLogin'];

export function createRouter(log, store) {
  const router = new VueRouter({
    mode: 'history',
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
      // Based on https://dev.to/napoleon039/the-lesser-known-amazing-things-vuerouter-can-do-25di
      if (to.hash) {
        return { selector: to.hash };
      }

      return { x: 0, y: 0 };
    },
    linkActiveClass: 'is-active',
  });

  function redirect({ route, from }) {
    let firstRoute = from.name === null && from.path === '/';

    // If first route (i.e. from server-side) we want to replace the history entry
    // since we don't want it accessible (since the browser adds it despite not
    // really being a proper entry vue-wise). After that, we want to keep the previous
    // entry intact, since it's not the auth restricted route (since it's actually the
    // previous route and not the same route loading server-side and then initiated again
    // client side).
    // Abort function needed since if we for example redirect to the same route it will get
    // aborted and not trigger afterEach.
    if (firstRoute) {
      router.replace(route, null, () => {
        store.general.loading = false;
      });
    } else {
      router.push(route, null, () => {
        store.general.loading = false;
      });
    }
  }

  router.beforeEach((to, from, next) => {
    store.general.loading = true;

    // Close opened popups
    Vue.prototype.$popover.closeAll();

    // Set return url
    if (restrictedReturnRoutesNames.indexOf(to.name) == -1) {
      router.returnUrl = to.fullPath || '/';
    } else {
      router.returnUrl = router.returnUrl || '/';
    }

    next();
  });

  // Handle routes that require auth
  router.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      // Already logged in, nothing to see here
      if (store.user.loggedIn) return next();

      // Trying to access restricted route despite not being logged - check
      // if truly not logged in. If truly not, redirect to login
      // TODO/REVIEW: Now since we've fixed the SSR issue, we should review
      // if this is really needed anymore.
      await store.user.checkStatus();

      if (!store.user.loggedIn) {
        redirect({ route: { path: '/account/login' }, from });
      } else {
        next();
      }
    } else {
      next();
    }
  });

  // Handle routes not allowed as a supplier
  router.beforeEach((to, from, next) => {
    if (!store.user.isSupplier || !to.matched.some((record) => record.meta.supplier)) return next();

    redirect({ route: { name: to.matched.find((record) => record.meta.supplier).meta.supplier }, from });
  });

  router.afterEach((to, from) => {
    store.general.loading = false;
  });

  return router;
}
