<template>
  <div id="app" :class="$route.name">
    <navbar></navbar>
    <section class="main">
      <router-view :key="$route.fullPath"></router-view>
    </section>
    <div class="loader" :class="{ 'is-visible': $store.general.loading && !$store.general.loadingLocked }"></div>

    <transition name="fade-in-only">
      <span class="plm-loader" v-if="$store.general.plmLoading"></span>
    </transition>

    <popover-container>
      <template #header><simple-close /></template>
    </popover-container>
    <popover name="error">
      <error-component
        :title="error.title"
        :message="error.message"
        :refresh="error.refresh"
        :fixed="error.fixed"
      ></error-component>
    </popover>
    <footer-component v-if="!$route.path.startsWith('/plm/')"></footer-component>
  </div>
</template>

<script>
import Navbar from './navbar/navbar.vue';
import FooterComponent from './footer.vue';
import ErrorComponent from './../error.vue';
import SimpleClose from 'common/plugins/vue-ontop/headers/simple-close.vue';
import * as Sentry from '@sentry/umd'; // Fake package that get's resolved server/client (@sentry/browser vs @sentry/node) using webpack alias

export default {
  components: { Navbar, FooterComponent, SimpleClose, ErrorComponent },
  props: ['bootError'],
  load({ store, route, context }) {
    return Promise.all([
      // Check user status
      store.user.checkStatus(),
      // Check if chineese
      store.user.checkIfChineese(),
      // Load categories with articles
      store.categories.load(),
      // Load all articles titles
      store.knowledgebank.loadArticleTitles(),
    ]);
  },
  data() {
    return {
      error: {},
    };
  },
  mounted() {
    if (this.bootError) {
      this.error = this.bootError;
      this.$popover.open('error');
    }
  },
  errorCaptured(error, vm, info) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.captureException(error, {
        contexts: { vueInfo: info },
        extra: { vueComponentName: vm.$options.name },
      });
    }

    // API call error (5xx)
    // The extensive check of error.response.data is due to making sure error.response.data is an actual object
    if (typeof error?.response?.data === 'object' && 'errorCode' in error.response.data) {
      this.error = error.response.data;
      this.$popover.open('error');
      return false;
    }

    if (process.env.NODE_ENV === 'development' && typeof error?.response?.data === 'string') {
      console.error(`In the context of "${info}" and component "${vm.$options.name}" there was an error.`, error);
      console.error(error.response.data);

      this.error = {
        title: 'Local development error',
        message: 'See the browser console for more information.',
        refresh: false,
      };
      this.$popover.open('error');
      return false;
    }

    // API call error due to not being logged in anymore
    if ([401, 405].includes(error?.request?.status) && error.request.responseURL?.includes('NotLoggedIn')) {
      this.error = {
        title: 'Logged out',
        message: 'For unknown reasons, you were logged out.',
        refresh: true,
      };
      this.$popover.open('error');
      return false;
    }

    if (error) {
      // Any error. Could be for example:
      // - a generic 'Network error' (see https://github.com/axios/axios/issues/383 for details)
      // - anything else bad in JS, e.g. TypeError etc.
      this.error = {
        title: 'An unknown error occurred!',
        message: 'For unknown reasons, an error occured.',
        refresh: true,
      };
      this.$popover.open('error');
      // We still want to report these, so that we have an understanding of when these occurs.
      // In the future, if we find a pattern (never any issue we could actually fix), this
      // might change and we might suppress it.
    }
  },
};
</script>

<style lang="scss">
@use 'sass:math';
@import 'common/styles/variables.scss';

// Fonts
@import 'common/styles/fonts/index';
// Settings
@import 'common/styles/media-queries';
@import 'common/styles/text';

// Popover
@import 'common/plugins/vue-ontop/styles/simple';

// Shared styles
@import 'common/styles/post-content';
@import 'common/styles/supplier';
@import 'common/styles/quotation-form';
@import 'common/styles/quotation-info';
@import 'common/styles/quotation-intro';
@import 'common/styles/transitions';

@import 'common/styles/link';
@import 'common/styles/custom-checkbox';
@import 'common/styles/field';
@import 'common/styles/transitions';
@import 'common/styles/snack-bar';
/* Can not be included until we've fixed all pages in knowledge base */
/*@import "common/styles/article";*/
@import 'common/styles/datepicker';

* {
  box-sizing: border-box;
}

body {
  overflow-y: scroll;
}

html,
body,
button {
  font-size: 14px;
  @include desktop-min {
    font-size: 16px;
  }
}
html {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Lato', sans-serif;
  color: $c-dark;
  overflow-x: hidden;
  padding-top: 3.5rem;
  @include tablet-min {
    padding-top: 4rem;
  }
  @include desktop-min {
    padding-top: 4.375rem;
  }
  @media print {
    padding-top: 0;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}
a {
  text-decoration: none;
}
button {
  font-family: 'Lato', sans-serif;
}
img {
  display: block;
  max-width: 100%;
}
svg {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
/* Clears the 'X' from Internet Explorer */
input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* Clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
input,
textarea {
  font-family: 'Lato', sans-serif;
}
.main {
  min-height: calc(100vh - #{math.div(31.2rem, 1.6)});
  & > * {
    top: 0;
  }
  .guide & {
    overflow: visible;
  }
  &--padding {
    padding: $main-padding-top $main-padding-sides $main-padding-bottom;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.2s;
}
.fade-enter-active {
  transition-delay: 0.2s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

// Popover
.popover {
  &__overlay {
    background: rgba($c-dark, 0.85);
  }

  &__popover {
    background: $c-light;
  }

  &__close {
    background: $c-light-gray;

    &:before,
    &:after {
      background: $c-dark;
    }
  }
}

// Quill
.main .ql-container {
  font-family: 'Lato', sans-serif;
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
.ql-align-right {
  text-align: right;
}
.ql-snow {
  &.ql-toolbar {
    .ql-formats {
      a,
      button {
        &:hover,
        &:focus,
        &.ql-active {
          color: $c-blue;
          .ql-stroke,
          .ql-fill {
            color: $c-blue;
          }
        }
      }
    }
  }
}

// Loader
.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11; // TODO: REVIEW - previously 6 but changed to accomodate product-details-flyout (with z-index 10)
  width: 100%;
  height: 100vh;
  background: rgba($c-light, 0.95);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  &.is-visible {
    visibility: visible;
    opacity: 1;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: calc(50% - #{math.div(1rem, 1.6)});
    top: calc(50% - #{math.div(1rem, 1.6)});
    height: math.div(2rem, 1.6);
    width: math.div(2rem, 1.6);
    border: 2px solid $c-blue;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    animation: spinAround 500ms infinite linear;
  }
}

// plm-loader inspiration: https://fareco.github.io/css-loader/ https://github.com/fareco/css-loader
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.plm-loader {
  position: fixed;
  height: math.div(2.8rem, 1.6);
  width: math.div(2.8rem, 1.6);
  left: math.div(3.4rem, 1.6); // page padding - half width = 48 - 28/2 = 34
  bottom: math.div(3.2rem, 1.6);
  z-index: 10000;

  @mixin beforeAfter() {
    position: absolute;
    height: 100%;
    width: 100%;
    content: '';
    border: 2.8px solid;
    border-radius: $br-round-corner;
  }

  &::before {
    @include beforeAfter();
    opacity: 0.3;
    border-color: $c-dark;
  }

  &::after {
    @include beforeAfter();
    border-color: transparent;
    border-bottom-color: $c-dark;
    animation: rotate 1s infinite linear;
  }

  &.fade-in-only-enter-active {
    transition: opacity 1000ms 250ms;
  }

  &.fade-in-only-leave-active {
    transition: opacity 50ms;
  }

  &.fade-in-only-enter,
  &.fade-in-only-leave-to {
    opacity: 0;
  }
}

// Page
.page {
  padding: math.div(2rem, 1.6) math.div(1.5rem, 1.6);
  &__heading {
    font-weight: normal;
    font-size: math.div(2.4rem, 1.6);
    text-align: center;
    letter-spacing: 1.4px;
    margin: 0;
    @include tablet-min {
      font-size: math.div(4rem, 1.6);
      margin-top: math.div(3rem, 1.6);
    }
    @include tablet-landscape-min {
      width: math.div(90rem, 1.6);
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// Account
.account {
  &__heading {
    font-weight: normal;
    font-size: math.div(2.4rem, 1.6);
    text-align: center;
    letter-spacing: 1.4px;
    margin: math.div(3rem, 1.6) 0 0;
    @include tablet-min {
      margin-top: math.div(4.5rem, 1.6);
      font-size: math.div(3.6rem, 1.6);
    }
  }
  &__subheading {
    display: block;
    margin: math.div(1.5rem, 1.6) 0 0;
    text-align: center;
    font-size: math.div(1.6rem, 1.6);
  }
  &__content {
    padding: 0 math.div(1.5rem, 1.6);
  }
}

@import 'common/styles/old-form';
@import 'common/styles/form';

// Google Autocomplete
.pac-container {
  width: 100%;
  margin: math.div(0.5rem, 1.6) 0 0;
  border: 1px solid rgba($c-dark, 0.2);
  box-shadow: none !important;
  border-radius: 0 !important;
  font-family: 'Lato', sans-serif;
  color: $c-gray;
  &:after {
    display: none;
  }
  .pac-item {
    padding: math.div(0.5rem, 1.6) math.div(1rem, 1.6);
    &:first-child {
      border-top: 0;
    }
  }
  .pac-icon.pac-icon-marker {
    display: none;
  }
  .pac-item-query {
    color: $c-dark;
  }
}

// Animations
@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

// Transitions
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/*.easter__chicken {
  position: relative;
  bottom: 12rem;
  left: 50%;
  height: 14rem;
  width: calc(6rem + 5vw);
  margin-top: -14rem;
  background-image: url("../img/easter/chicken.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}*/

// ==========================================================================
// ==========================================================================
// Popper
.popper-wrapper {
  z-index: $zi-popper;
}

.mx-datepicker-popup {
  z-index: $zi-datepicker;
}
</style>
