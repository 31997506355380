let differenceInMilliseconds = require('date-fns/differenceInMilliseconds');
let format = require('date-fns/format');

// ES module syntax and commonjs interoperability
if(differenceInMilliseconds.default) differenceInMilliseconds = differenceInMilliseconds.default;
if(format.default) format = format.default;

// A common log convenience interface for both backend and frontend
// Dependent on getting a Minilog instance already setup for the
// proper environment.
class Log {
  constructor({ minilog, dateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", path = '', location = '' }) {
    this.minilog = minilog;
    this.dateTimeFormat = dateTimeFormat;
    this.path = path;
    this.location = location;
  }
  _format(dateTime) {
    return format(dateTime, this.dateTimeFormat);
  }
  _log(messages = [], { level = 'info', category = 'General', location, path }) {
    const now = new Date();

    if(!Array.isArray(messages)) messages = [messages];

    messages = messages.reduce((messages, message) => {
      if(message instanceof Date) message = this._format(message);
      
      return `${messages}\t${message}`;
    }, '');

    if(location && this.location) {
      location = `${this.location} -> ${location}`;
    } else {
      location = location || this.location;
    }

    path = path || this.path;

    let message = `[${this._format(now)}]\t${category}\t${location}\t${path}${messages}`;

    this.minilog[level](message);
  }
  // Make minilogs methods accessible straight from the Log class
  info(messages, options = {}) {
    this._log(messages, Object.assign({ level: 'info' }, options));
  }
  log(messages, options = {}) {
    this._log(messages, Object.assign({ level: 'log' }, options));
  }
  debug(messages, options = {}) {
    this._log(messages, Object.assign({ level: 'debug' }, options));
  }
  warn(messages, options = {}) {
    this._log(messages, Object.assign({ level: 'warn' }, options));
  }
  error(messages, options = {}) {
    this._log(messages, Object.assign({ level: 'error' }, options));
  }
  start(options) {
    return new PerformanceLog(Object.assign({log: this}, options))
  }
}

class PerformanceLog {
  constructor({ log, level = 'info', location = '', startTime = new Date(), parent } = {}) {
    this._log = log;
    this.level = level;
    this.startTime = startTime;
    this._location = location;
    this.pings = [];
    this.children = [];
    this.parent = parent;
  }
  get location() {
    const base = this.parent ? this.parent.location : '';

    if(base && this._location) return `${base} -> ${this._location}`;

    return base || this._location;
  }
  log({ type, comment, now }) {
    const timePassed = differenceInMilliseconds(now, this.startTime);

    const category = `Performance -> ${type}`;
    const messages = [];
    messages.push(this.startTime);
    messages.push(now);
    messages.push(`${timePassed}ms`);

    if(comment) messages.push(`\tComment: ${comment}`);
  
    this._log[this.level](messages, { location: this.location, category });
  }
  ping({ comment } = {}) {
    this.log({ type: 'ping', comment, now: new Date() });
  }
  end({ comment, fromParent = false } = {}) {
    if(!fromParent && this.parent) this.parent.removeChild(this);

    this.children.forEach(child => child.end({ fromParent: true }));

    this.log({ type: 'end', comment, now: new Date()});
  }
  removeChild(child) {
    this.children = this.children.filter((c) => c != child);
  }
  start({ location = '' }) {
    const newPerformanceLog = this._log.start({ level: this.level, location, parent: this });

    this.children.push(newPerformanceLog);

    return newPerformanceLog;
  }
}

module.exports = { Log, PerformanceLog };

