<template>
  <header :class="{ header: true, 'header--min': headerMin }">
    <!-- Logo -->
    <router-link
      :class="{ header__logo: true, 'header__logo--is-centered': menuIsOpened }"
      :to="{ name: 'home' }"
      exact
    >
      <strong v-html="icons.singles.logoIcon"></strong>
    </router-link>

    <!-- Back button -->
    <a v-if="menuIsOpened && submenuIsOpened" class="back-button" href="#" role="button" @click.prevent="menuGoBack()">
      Back
    </a>

    <!-- Menu button -->
    <a class="menu-button" href="#" role="button" @click.prevent.stop="toggleMenu()">
      <span class="menu-button__label"> Menu </span>
      <div :class="{ 'menu-button__icon': true, 'menu-button__icon--is-active': menuIsOpened }">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </a>

    <!-- Main menu, Profile menu, Categories/articles menu -->
    <div :class="{ 'menu-container': true, 'menu-container--is-opened': menuIsOpened }">
      <!-- Main Menu -->
      <!-- Logged in -->
      <ul v-if="user" :class="{ menu: true, 'menu--is-hidden': submenuIsOpened }">
        <li class="menu__item">
          <router-link class="menu__link" :to="{ name: $store.user.isBuyer ? 'quotations' : 'quotationOverview' }">
            Quotation Requests
          </router-link>
        </li>
        <li class="menu__item">
          <router-link class="menu__link" :to="{ name: 'suppliers' }"> Suppliers </router-link>
        </li>
        <li class="menu__item">
          <router-link class="menu__link menu__link--tablet" :to="{ name: 'articles' }"> Knowledge bank </router-link>
          <a class="menu__link menu__link--mobile menu__link--is-parent" href="#" @click.prevent="openCategoriesMenu">
            Knowledge bank
          </a>
        </li>
        <li v-if="$store.user.isBuyer" class="menu__item">
          <router-link class="menu__link" :to="{ name: 'quotationForm' }" exact>Submit Quotation Request</router-link>
        </li>
        <li class="menu__item menu__item--right">
          <!-- Companies -->
          <!-- TODO: FIXME style is off here. We should probably review/refactor
              all menu code when we want to fix this though, since it's a pretty big mess -->
          <router-link
            v-if="!user.companies.length"
            class="menu__link"
            :to="{ name: 'addCompany' }"
            style="margin-right: 0"
          >
            Add company
          </router-link>
          <!-- Profile -->
          <a
            href="#"
            :class="{
              menu__link: true,
              'menu__link--is-parent': true,
              'menu__link--mobile': true,
              'is-active': profileMenuIsOpened,
            }"
            @click.prevent.stop="toggleProfileMenu()"
          >
            Profile
          </a>
          <a
            :class="{
              menu__link: true,
              'menu__link--big-icon': true,
              'menu__link--tablet': true,
              'is-active': profileMenuIsOpened,
            }"
            href="#"
            @click.prevent.stop="toggleProfileMenu()"
          >
            {{ ($store.user.company || {}).name }}
            <span v-if="!user.photo" class="menu__link menu__link-icon" v-html="icons.singles.accountIcon"></span>
            <span
              v-if="user.photo"
              class="menu__link menu__link-icon"
              :style="{ backgroundImage: 'url(' + userPhoto + ')' }"
            >
              <img :src="userPhoto" />
            </span>
          </a>
        </li>
      </ul>
      <!-- Not logged in -->
      <ul v-else :class="{ menu: true, 'menu--is-hidden': submenuIsOpened }">
        <li class="menu__item">
          <router-link class="menu__link" :to="{ name: 'footwearSuppliers' }"> Footwear Suppliers </router-link>
        </li>
        <li class="menu__item">
          <router-link class="menu__link" :to="{ name: 'componentSuppliers' }"> Component Suppliers </router-link>
        </li>
        <li class="menu__item">
          <router-link class="menu__link menu__link--tablet" :to="{ name: 'articles' }"> Knowledge bank </router-link>
          <a class="menu__link menu__link--mobile menu__link--is-parent" href="#" @click.prevent="openCategoriesMenu">
            Knowledge bank
          </a>
        </li>
        <li class="menu__item">
          <router-link class="menu__link" :to="{ name: 'quotation' }" exact> Submit Quotation Request </router-link>
        </li>
        <li class="menu__item menu__item--right">
          <router-link class="menu__link menu__link--special-hide" :to="{ name: 'supplierRegister' }" exact>
            Join as a Factory/Agent
          </router-link>
          <router-link class="menu__link" :event="''" :to="{ name: 'login' }" @click.native="$popover.open('logIn')">
            Login
          </router-link>
        </li>
      </ul>

      <!-- Knowledge Bank Categories Menu -->
      <ul
        :class="{
          'menu menu--categories': true,
          'menu--is-active': categoriesMenuIsOpened,
          'menu--is-hidden': articlesMenuIsOpened,
        }"
      >
        <li class="menu__item" v-for="(category, i) in categories" :key="`category${i}`">
          <a class="menu__link menu__link--is-parent" href="#" @click.prevent="openArticlesMenu(i)">
            {{ category.title }}
          </a>
        </li>
      </ul>
      <!-- Knowledge Bank Articles Menu -->
      <ul :class="{ menu: true, 'menu--articles': true, 'menu--is-active': articlesMenuIsOpened }">
        <li class="menu__item" v-for="(article, i) in activeCategory.articles" :key="`article${i}`">
          <router-link
            class="menu__link"
            :to="{ name: 'article', params: { category: activeCategory.slug, slug: article.slug } }"
          >
            {{ article.title }}
          </router-link>
          <router-link
            class="menu__link menu__link--is-child"
            v-for="subArticle in article.subArticles"
            :to="{ name: 'article', params: { category: activeCategory.slug, slug: subArticle.slug } }"
            :key="subArticle.id"
          >
            {{ subArticle.title }}
          </router-link>
        </li>
      </ul>
      <!-- Profile Menu -->
      <div v-if="user" :class="{ 'dropdown-menu': true, 'dropdown-menu--is-active': profileMenuIsOpened }" @click.stop>
        <div class="dropdown-menu__section dropdown-menu__profile">
          <span v-if="!user.photo" class="dropdown-menu__profile-image" v-html="icons.singles.accountIcon"></span>
          <span
            v-if="user.photo"
            class="dropdown-menu__profile-image"
            :style="{ backgroundImage: 'url(' + userPhoto + ')' }"
          ></span>
          <router-link
            class="dropdown-menu__profile-edit"
            :to="{ name: 'editProfile' }"
            v-html="icons.singles.editIcon"
          ></router-link>
          <strong :class="{ 'dropdown-menu__profile-name': true, 'dropdown-menu__profile-name--no-social': true }">
            {{ user.name || '&lt;Your name&gt;' }}
          </strong>
          <span class="dropdown-menu__profile-email">
            {{ user.userName }}
          </span>
          <div v-if="false" class="dropdown-menu__profile-social">
            <a class="dropdown-menu__profile-social-icon" href="#" v-html="icons.singles.linkedinIcon"></a>
            <a class="dropdown-menu__profile-social-icon" href="#" v-html="icons.singles.linkedinIcon"></a>
          </div>
        </div>
        <div class="dropdown-menu__section dropdown-menu__section--companies">
          <div
            :class="{
              'dropdown-menu__company': true,
              'is-selectable': user.companies.length > 1,
              'is-selected': $store.user.isSelectedCompany(company),
            }"
            v-for="company in user.companies"
            :key="company.id"
          >
            <div class="dropdown-menu__company-content-group">
              <strong class="dropdown-menu__company-header">
                {{ company.name }}
              </strong>
              <span v-if="company.email" class="dropdown-menu__company-email">
                {{ company.email }}
              </span>
              <span v-if="!company.email" class="dropdown-menu__company-email">
                <i>No e-mail registered</i>
              </span>
            </div>
            <div class="dropdown-menu__company-action-group">
              <!--
              -->
              <router-link
                v-if="company.type !== 1"
                class="dropdown-menu__company-link"
                :to="{ name: 'supplier', params: { name: company.slug } }"
              >
                <span class="dropdown-menu__company-link-icon" v-html="icons.singles.descriptionIcon"></span>
                View
              </router-link>
              <!--
              -->
              <router-link
                class="dropdown-menu__company-link"
                :to="
                  company.type === 1 ? { name: 'quotationForm' } : { name: 'supplierEdit', params: { id: company.id } }
                "
              >
                <span class="dropdown-menu__company-link-icon" v-html="icons.singles.editIcon"></span>
                Edit
              </router-link>
              <!--
              -->
              <router-link
                v-if="false"
                class="dropdown-menu__company-link"
                :to="{ name: 'supplier', params: { name: company.slug } }"
              >
                <span class="dropdown-menu__company-link-icon" v-html="icons.singles.delIcon"></span>
                Remove
              </router-link>
              <!--
              -->
            </div>
          </div>
        </div>
        <div class="dropdown-menu__section dropdown-menu__section--right-aligned">
          <a v-if="$store.user.isPlm" class="dropdown-menu__action" href="/plm/products"> PLM </a>
          <a v-if="$store.user.isAdmin" class="dropdown-menu__action" href="/admin"> Admin </a>
          <router-link class="dropdown-menu__action" :to="{ name: 'login' }" v-if="false"> Add company </router-link>
          <a class="dropdown-menu__action" href="#" @click.prevent="logOut()"> Logout </a>
        </div>
      </div>
    </div>

    <!-- Progress bar -->
    <div v-if="progressBar" class="progress-bar" :style="{ width: progressBarWidth + '%' }"></div>

    <!-- Login/Registration popups -->
    <popover name="logIn">
      <LogIn mode="popup" :email.sync="email" :password.sync="password" :toggled.sync="toggled"></LogIn>
    </popover>
    <popover name="signUp">
      <SignUp mode="popup" :email.sync="email" :password.sync="password" :toggled.sync="toggled"></SignUp>
    </popover>
    <popover name="forgotPassword">
      <ForgotPassword mode="popup" :email.sync="email"></ForgotPassword>
    </popover>
    <popover v-if="$store.user.needMoreInfo" name="moreInfo" force :overlayClose="false" :escClose="false">
      <template #header></template>
      <MoreInfo></MoreInfo>
    </popover>
  </header>
</template>

<script>
import { imagePath } from '@lib/imgproxy';

// Components
const ForgotPassword = () => import('./../../account/forgot-password.vue');
const LogIn = () => import('./../../account/log-in.vue');
const MoreInfo = () => import('./../../account/more-info.vue');
const SignUp = () => import('./../../account/sign-up.vue');

// Icons
import interfaceElementsIcons from '@lib/icons-imports/interface-elements';
// singles
import accountIcon from '../../../img/baseline-account_circle-24px.svg';
import componentsIcon from '@icons/other2/components.svg';
import delIcon from '../../../img/baseline-delete-24px.svg';
import descriptionIcon from '../../../img/baseline-description-24px.svg';
import editIcon from '../../../img/baseline-edit-24px.svg';
import linkedinIcon from '../../../img/linkedin-s-icon.svg';
import logoIcon from '../../../img/logo.svg';
import sneakersIcon from '@icons/footwear-types/sneakers.svg';

export default {
  components: {
    ForgotPassword,
    LogIn,
    MoreInfo,
    SignUp,
  },

  data() {
    return {
      newProductName: null,
      headerMin: false,
      progressBarWidth: 0,
      menuIsOpened: false,
      profileMenuIsOpened: false,
      companyMenuIsOpened: false,
      categoriesMenuIsOpened: false,
      articlesMenuIsOpened: false,
      activeCategoryIndex: 0,
      icons: {
        interfaceElements: interfaceElementsIcons,
        singles: {
          accountIcon,
          componentsIcon,
          delIcon,
          descriptionIcon,
          editIcon,
          linkedinIcon,
          logoIcon,
          sneakersIcon,
        },
      },
      email: '',
      password: '',
      toggled: false,
    };
  },
  computed: {
    isQA() {
      return window.location?.href.includes('qa');
    },
    user() {
      return this.$store.user.current;
    },
    userPhoto() {
      return imagePath(`images/${this.user?.photo}`, 500, 300);
    },
    categories() {
      return this.$store.categories.all.filter((c) => {
        return c.articles.length;
      });
    },
    activeCategory() {
      return this.categories[this.activeCategoryIndex] || null;
    },
    progressBar() {
      return this.$route.meta.pageType === 'article';
    },
    submenuIsOpened() {
      return (
        this.profileMenuIsOpened || this.companyMenuIsOpened || this.categoriesMenuIsOpened || this.articlesMenuIsOpened
      );
    },
  },
  methods: {
    //toggleHeader
    toggleHeaderMini() {
      if ((!this.headerMin && window.pageYOffset >= 100) || (this.headerMin && window.pageYOffset < 100)) {
        this.headerMin = !this.headerMin;

        // IE fix for translating/transforming the S in the FS logo - do not need delay since translation is instant in IE (see translation-CSS excluding IE)
        // See https://stackoverflow.com/questions/23047098/css-translate-not-working-in-ie11-on-svg-g for more details
        if (typeof navigator !== 'undefined' && /MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
          this.$nextTick(() => {
            var $el = document.getElementsByClassName('fs-logo__s')[0];
            var transform = getComputedStyle($el).getPropertyValue('transform');
            $el.setAttribute('transform', transform);
          });
        }
      }
    },
    handleProgressBar() {
      if (this.progressBar) {
        let scrollProgressSection = document.querySelector('.scroll-progress-section');
        if (scrollProgressSection) {
          let scrollHeightValue =
            scrollProgressSection.offsetHeight + scrollProgressSection.offsetTop - window.outerHeight;
          if (scrollHeightValue >= window.pageYOffset) {
            this.progressBarWidth = (window.pageYOffset / scrollHeightValue) * 100;
          } else {
            this.progressBarWidth = 100;
          }
        }
      }
    },
    handleMainHeader() {
      this.toggleHeaderMini();
      this.handleProgressBar();
    },
    // Toggle Mobile Menu
    toggleMenu() {
      if (this.menuIsOpened) {
        this.closeMenu();
      } else {
        this.menuIsOpened = true;
      }
    },
    // Close Menu
    closeMenu() {
      this.menuIsOpened = false;
      setTimeout(() => {
        this.profileMenuIsOpened = false;
        this.companyMenuIsOpened = false;
        this.categoriesMenuIsOpened = false;
        this.articlesMenuIsOpened = false;
      }, 500);
    },
    // Back menu button
    menuGoBack() {
      if (this.profileMenuIsOpened) {
        this.profileMenuIsOpened = false;
        return;
      }
      if (this.companyMenuIsOpened) {
        this.companyMenuIsOpened = false;
        return;
      }
      if (this.articlesMenuIsOpened) {
        this.articlesMenuIsOpened = false;
        return;
      }
      if (this.categoriesMenuIsOpened) {
        this.categoriesMenuIsOpened = false;
        return;
      }
    },
    // Profile Menu
    toggleProfileMenu() {
      this.menuIsOpened = !this.profileMenuIsOpened;
      this.profileMenuIsOpened = !this.profileMenuIsOpened;

      if (this.profileMenuIsOpened) this.companyMenuIsOpened = false;
    },
    // Company Menu
    toggleCompanyMenu() {
      this.menuIsOpened = !this.companyMenuIsOpened;
      this.companyMenuIsOpened = !this.companyMenuIsOpened;

      if (this.companyMenuIsOpened) this.profileMenuIsOpened = false;
    },
    // Categories Menu
    openCategoriesMenu() {
      this.menuIsOpened = true;
      this.categoriesMenuIsOpened = true;
    },
    // Articles Menu
    openArticlesMenu(i) {
      this.activeCategoryIndex = i;
      this.menuIsOpened = true;
      this.articlesMenuIsOpened = true;
    },
    // Methods For Listeners
    windowScroll() {
      this.handleMainHeader();
    },
    windowResize() {
      this.handleMainHeader();
    },
    logOut() {
      this.closeMenu();
      this.$store.user.logOut().then(() => {
        if (this.$route.meta.requiresAuth) {
          this.$router.push({ name: 'home' });
        }
      });
    },
  },
  watch: {
    'user.id'() {
      this.menuIsOpened = false;
    },
    $route(to, from) {
      this.closeMenu();
      this.progressBarWidth = 0;
    },
  },
  mounted() {
    // Add listeners
    window.addEventListener('scroll', this.windowScroll);
    window.addEventListener('resize', this.windowResize);
    window.addEventListener('click', () => {
      this.profileMenuIsOpened = false;
      this.companyMenuIsOpened = false;
    });
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';

$dropdown-icon-size: math.div(2.8rem, 1.6);

@mixin header-link-color {
  color: $c-dark;
  transition: color 0.5s ease;

  svg {
    fill: $c-dark;
    transition: fill 0.5s ease;
  }

  &:hover,
  &.is-active {
    color: $c-blue;

    svg {
      fill: $c-blue;
    }
  }
}

.header {
  @mixin short-logo {
    .fs-logo__part2,
    .fs-logo__part1 {
      opacity: 0;
    }

    .fs-logo__f,
    .fs-logo__s {
      opacity: 1;
    }

    .fs-logo__s {
      transform: translateX(#{math.div(-4.4rem, 1.6)});
    }
  }

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10005;
  width: 100%;
  height: 3.5rem;
  border-bottom: 1px solid rgba($c-black, 0.06);
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background: $c-bg-light;
  /* box-shadow: 0px -9px 4px 8px rgba(0, 0, 0, 0.18); /**/
  /* box-shadow: 0px -4px 7px 6px rgba($c-black,0.75); /**/
  /* box-shadow: 0px 1px 3px 0px rgba($c-black,0.18); /**/
  box-shadow: $bs-subtle;
  transition: height 0.3s ease;

  &__main-logo {
  }

  &__user-menu-icons {
    height: 100%;
    margin-right: math.div(4.2rem, 1.6) !important;
  }

  .guide & {
    position: absolute;
  }
  @include tablet-min {
    padding: 0 1.5rem;
    height: 4rem;
    flex-wrap: wrap;
  }
  @include tablet-landscape-min {
    padding: 0 2rem;
    flex-wrap: nowrap;
  }
  @include desktop-min {
    height: 4.375rem;
    &--min {
      height: 3.5rem;

      .header__logo {
        @include short-logo;
      }
    }
  }

  &__product-search-field {
    margin-right: math.div(1.3rem, 1.6);
  }

  &__new-product-but {
    margin-right: math.div(1.5rem, 1.6);
  }

  &__logo {
    position: relative;
    z-index: 2;
    width: math.div(12rem, 1.6);
    height: 100%;
    // IE "fix" - or rather removal of not working functionality for IE only (based on browserhacks.com).
    // Transformations will be instant instead.
    @supports (-webkit-appearance: none) {
      transition: width 0.2s ease, transform 0.2s ease, margin 0.2s ease;
      path {
        transition: transform 0.2s ease, opacity 0.2s ease;
      }
    }

    @include mobile-only {
      &--is-centered {
        transform: translateX(-50%);
        margin-left: 50%;
      }
    }
    @include desktop-min {
      width: math.div(17.7rem, 1.6);
      .header--min & {
        width: math.div(14rem, 1.6);
      }
    }

    .fs-logo__part2,
    .fs-logo__part1 {
      transform: scaleX(1);
    }

    @include tablet-only {
      @include short-logo;
    }

    &--short {
      @include short-logo;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.menu-container {
  @include mobile-only {
    position: absolute;
    width: 100%;
    height: calc(100vh - #{math.div(4.6rem, 1.6)});
    top: 100%;
    left: 0;
    margin-top: 1px;
    background: $c-light;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.25s ease, opacity 0.25s ease;

    &--is-opened {
      visibility: visible;
      opacity: 1;
    }
  }
}

.menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: transform 0.5s ease;
  &--categories,
  &--articles {
    transform: translateX(100%);
  }
  @include mobile-only {
    padding: 0 0 math.div(4rem, 1.6);
    overflow-y: auto;
    &--is-active {
      transform: translateX(0%);
    }
    &--is-hidden {
      transform: translateX(-100%);
    }
  }
  @include tablet-min {
    display: flex;
    align-items: center;
    justify-content: center;
    &--categories,
    &--articles {
      display: none;
    }
  }
  &__item {
    fill: $c-dark;

    @include tablet-min {
      display: flex;
      align-items: center;
      height: 100%;
    }

    &--right {
      @include tablet-min {
        position: absolute;
        z-index: 2;
        right: 1.5rem;
        top: 0;
        /*text-transform: uppercase;*/
        white-space: nowrap;
      }
      @include tablet-landscape-min {
        right: 2em;
      }
    }
  }
  &__link {
    @include link;
    @include header-link-color;

    position: relative;
    display: block;
    cursor: pointer;
    position: relative;
    margin: 0 1.25rem;

    &--big-icon {
      padding-right: calc(#{$dropdown-icon-size} + 1.25rem);
    }

    &--special-hide {
      @media (max-width: 1250px) {
        @include tablet-min {
          display: none;
        }
      }
    }

    &-icon {
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: math.div(-$dropdown-icon-size, 2);
      display: inline-block;
      height: $dropdown-icon-size;
      width: $dropdown-icon-size;
      border-radius: 50%;
      overflow: hidden;
      background-size: cover;
      background-position: center;

      svg {
        height: $dropdown-icon-size;
        width: $dropdown-icon-size;
      }

      img {
        // Using background-size cover is better due to better cross-browser compatibility,
        // but we need the image tag for detecting loading errors, so thus hiding image.
        visibility: hidden;
        height: $dropdown-icon-size;
        width: $dropdown-icon-size;
      }
    }
    @include mobile-only {
      padding: 1rem;
      margin: 0;
      border-bottom: 1px solid rgba($c-dark, 0.05);

      &--is-parent:before {
        content: '';
        position: absolute;
        top: 50%;
        right: math.div(2.1rem, 1.6);
        margin-top: math.div(-0.4rem, 1.6);
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: math.div(0.4rem, 1.6) 0 math.div(0.4rem, 1.6) math.div(0.3rem, 1.6);
        border-color: transparent transparent transparent $c-dark;
        transition: border-color 0.5s ease;
      }
      &--is-child {
        padding-left: math.div(3rem, 1.6);
        font-weight: normal;
        border-top: 1px solid rgba($c-dark, 0.05);
      }
      &:hover:before,
      &.is-active:before {
        border-color: transparent transparent transparent $c-blue;
      }
    }
    &--tablet {
      display: none;
    }
    @include tablet-min {
      /*margin: 0 10px;*/
      &--mobile {
        display: none;
      }
      &--tablet {
        display: block;
      }
    }
  }
  &__item:first-child &__link {
    margin-left: 0;
  }
  &__item:nth-last-child(2) &__link {
    margin-right: 0;
  }
  &__item--right &__link:first-child {
    margin-left: 0;
  }
  &__item--right &__link:last-child {
    margin-left: 0;
    margin-right: 0;
  }
  &__dropdown {
    &:after {
      @include tablet-min {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 0;
        margin: 0 0 0 0.5em;
        border-style: solid;
        border-width: math.div(0.4rem, 1.6) math.div(0.35rem, 1.6) 0 math.div(0.35rem, 1.6);
        border-color: $c-dark transparent transparent transparent;
        transition: transform 0.2s ease, border-color 0.5s ease;
      }
    }
    &:hover:after {
      @include tablet-min {
        border-color: $c-blue transparent transparent transparent;
      }
    }
    &.is-active {
      color: $c-dark;

      &:hover {
        color: $c-blue;
      }
      &:after {
        transform: scale(1, -1) translateY(1px);
      }
    }
  }
  // Menu button
  &-button {
    @include header-link-color;

    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    padding: 1rem;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    @include tablet-min {
      display: none;
    }
    &__label {
      margin-right: math.div(0.5rem, 1.6);
      font-weight: bold;
      font-size: math.div(1.2rem, 1.6);
      line-height: math.div(1rem, 1.6);
    }
    &__icon {
      width: math.div(1.4rem, 1.6);
      height: math.div(1rem, 1.6);
      span {
        display: block;
        width: 100%;
        height: 2px;
        background: $c-dark;
        transition: transform 0.5s ease, opacity 0.5s ease, background-color 0.5s ease;
        &:not(:first-child) {
          margin-top: 2px;
        }
      }
      &--is-active span {
        &:nth-child(1) {
          transform: translateY(#{math.div(0.4rem, 1.6)}) rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: translateY(#{math.div(-0.4rem, 1.6)}) rotate(-45deg);
        }
      }
    }
    &:hover &__icon span {
      background-color: $c-blue;
    }
  }
}

.dropdown-menu {
  position: absolute;
  top: 0;
  right: 0;
  background: $c-light;
  @include mobile-only {
    width: 100%;
    height: 100%;
    transform: translateX(100%);
    overflow-y: auto;
    transition: transform 0.5s ease;
    &--is-active {
      transform: translateX(0);
    }
  }
  @include tablet-min {
    font-size: 0.875em;
    top: 82.5%;
    right: 0.5rem;
    min-width: 20rem;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease, visibility 0.2s ease;
    border: 1px solid $c-light-gray;
    border-radius: $br-light;
    box-shadow: $bs-high-subtle;
    @include desktop-min {
      top: 80%;
    }
    &--is-active {
      visibility: visible;
      opacity: 1;
      z-index: 100;
    }
  }

  &__section {
    border-top: 1px solid rgba($c-dark, 0.05);
    padding: 0 1.25rem;

    &:first-child {
      border-top: none;
    }

    &--right-aligned {
      text-align: right;
    }

    &--companies {
      display: table;
      width: 100%;
      padding: 0;
    }
  }

  &__header {
    @include caption;
    display: inline-block;
    margin: 1rem 0 0.5rem 0;
  }

  &__action {
    @include link;
    @include header-link-color;
    display: inline-block;
    margin: 1.0625rem 0 1.0625rem 1.75em;
  }

  &__company {
    display: table-row;

    &-content-group {
      display: table-cell;
      position: relative;
      vertical-align: middle;
      padding: 1.125rem 1.25rem;
      width: 99%;

      &:before {
        @include mobile-only {
          content: '\00a0';
          visibility: hidden;
          line-height: 2.57rem;
        }
      }
    }

    &-action-group {
      display: table-cell;
      vertical-align: middle;
      padding: 0.875rem 1.25rem;
      white-space: nowrap;
    }

    &.is-selectable {
      cursor: pointer;

      &:hover {
        background-color: $c-light-gray;
      }
    }

    &.is-selected {
      cursor: auto;

      & > :first-child::after {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        content: '';
        border-left: math.div(0.3rem, 1.6) solid $c-blue;
      }
    }

    // Stolen from https://stackoverflow.com/questions/9789723/css-text-overflow-in-a-table-cell/30362531#30362531
    @mixin low-res-fix {
      position: absolute;
      left: 0;
      right: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 1.25rem;
    }

    &-header {
      display: block;
      margin: 0.0625rem 0;

      @include mobile-only {
        @include low-res-fix;
        margin-top: -2.5075rem;
      }
    }

    &-email {
      @include body--small;
      display: block;

      @include mobile-only {
        @include low-res-fix;
        margin-top: -1.214rem;
      }
    }

    &-link {
      position: relative;
      display: inline-block;
      @include link($size: 0.75em);
      @include header-link-color;
      margin-left: 0.375rem;
      margin-right: 0.375rem;
      padding: 1.325rem 0.3125rem 0.125rem 0.3125rem;
      text-align: center;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &-icon {
        display: block;
        position: absolute;
        top: 0.125rem;
        left: 50%;
        margin-left: -0.5rem;
        height: 1rem;
        width: 1rem;

        svg {
          height: 1rem;
          width: 1rem;
        }
      }
    }
  }

  &__profile {
    height: 6.5rem;
    padding: 1.25rem 5.5rem 1.25rem 6.5rem;

    &-image {
      position: absolute;
      left: 1.25rem;
      height: 4rem;
      width: 4rem;
      background-size: cover;
      background-position: center;
      border-radius: 50%;

      svg {
        height: 4rem;
        width: 4rem;
        fill: $c-dark;
        // Make it encompass the whole svg box
        transform: scale(1.2, 1.2);
      }
    }

    &-name {
      display: block;
      margin-top: 0.875rem; // TODO: FIXME
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &--no-social {
        margin-top: 0.875rem;
      }
    }

    &-email {
      @include body--small;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &-social {
      display: block;
      margin-top: 0.5725rem;

      &-icon {
        display: inline-block;
        height: 1.25rem;
        width: 1.25rem;
        margin-right: 0.125rem;
        padding: 0.125rem;

        svg,
        path {
          fill: $c-dark;
        }

        &:first-child {
          margin-left: -0.125rem;
        }
      }
    }

    &-edit {
      @include header-link-color;

      position: absolute;
      top: 1.525rem;
      right: 1.25rem;
      padding: 1rem;
      height: 3.25rem;
      width: 3.25rem;

      svg {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  }

  &__item {
    display: block;
    border-top: 1px solid rgba($c-dark, 0.05);
    padding: 1em 1.375em;
    &:first-child {
      border-top: none;
    }

    &--link {
      @include header-link-color;
    }

    /*&--*/

    &-link {
      display: block;
      @include link;
      @include header-link-color;
    }

    &-caption {
      @include caption;
    }
  }
}

// Back button
.back-button {
  display: none;
  @include mobile-only {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    color: $c-dark;
    font-weight: bold;
    font-size: math.div(1.2rem, 1.6);
    line-height: math.div(1rem, 1.6);
    transition: color 0.5s ease;
    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      margin-right: math.div(1rem, 1.6);
      border-style: solid;
      border-width: math.div(0.3rem, 1.6) math.div(0.3rem, 1.6) math.div(0.3rem, 1.6) 0;
      border-color: transparent $c-dark transparent transparent;
    }
  }
}

.progress-bar {
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: math.div(0.3rem, 1.6);
  background: $c-blue;
  z-index: 3;
  .category-opened ~ &,
  .menu-is-opened & {
    display: none;
  }
}

// Main logo ------------------------------------------------------------------
.main-logo {
  display: flex;
  align-items: center;
  font-size: math.div(1.75rem, 1.6);
  line-height: math.div(1.75rem, 1.6);
  letter-spacing: 0.5px;
  font-weight: $bold-lato;
  text-transform: uppercase;

  &__text-part {
    margin-right: math.div(1rem, 1.6);
  }
  &__qa-badge {
    width: math.div(2.5rem, 1.6);
    height: math.div(2.5rem, 1.6);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: math.div(1.1rem, 1.6);
    font-weight: 900;
    color: $c-dark;
    background: white;
  }
}

//popover
.header__popover {
  //moving popover to center of screen, overwriting default styles
  width: 25.25rem;
  z-index: 10100 !important;
  transform: translate(-50%, -50%) !important;
  left: 50% !important;
  top: 50% !important;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5) !important;

  &--title {
    display: block;
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-weight: normal;
    color: $c-dark;
    margin: 0 0 1rem;
  }

  &--input {
    width: 100%;
    height: 2.75rem;
    margin: 0 0 2rem;
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    line-height: 2.75rem;
    color: $c-dark;
    border: 1px solid rgba($c-dark, 0.25);
    border-radius: 0.625rem;

    &:focus {
      outline: none;
    }
  }

  &--btn-row {
    display: flex;
    align-items: center;
    justify-content: space-around;

    & > * {
      width: 8.4375rem;
    }
  }
}
</style>
